import { Intent } from "@blueprintjs/core";
import { ButtonContainer, DataTable, FieldSet, IDataTableColumn, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";
import {
  ApprenantFichierApi,
  ApprenantFichierGridDto,
  ApprenantFichierSearchDto,
  ETypeApprenantFichier
} from "../../../../../api";
import { AddButton, DeleteButton, EditButton, LinkButton } from "../../../../../components";
import { useEventsContext } from "../../../../../contexts";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface IApprenantAutresDocumentsProps {
  idapprenant: number;
  rowLoading: number;
  setCurrentApprenantFichierId: (idapprenantFichier: number) => void;
  deleteFichier: (idapprenantFichier: number) => void;
  downloadFichier: (idapprenantFichier: number) => void;
  onAdd: () => void;
}

export const ApprenantAutresDocuments: React.FunctionComponent<IApprenantAutresDocumentsProps> = ({
  idapprenant,
  rowLoading,
  setCurrentApprenantFichierId,
  deleteFichier,
  downloadFichier,
  onAdd
}) => {
  const { t } = useTl();
  const api = useApiService(ApprenantFichierApi);

  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: false,
    availablePageSizes: [5],
    pageSize: 5,
    sortKeys: { uploadDate: "DESC", fileName: "ASC" }
  });

  const searchFunc = React.useCallback(
    (nextSearch?: ApprenantFichierSearchDto) =>
      api.apprenantFichierBaseSearch({
        ApprenantFichierSearchDto: {
          ...nextSearch,
          idapprenant,
          excludedTypes: [
            ETypeApprenantFichier.AVIS_RESULTATS,
            ETypeApprenantFichier.AVIS_RESULTATS_1ERE_SESSION,
            ETypeApprenantFichier.AVIS_RESULTATS_2EME_SESSION,
            ETypeApprenantFichier.AVIS_RESULTATS_EXTERNE,
            ETypeApprenantFichier.ATTESTATION_REUSSITE,
            ETypeApprenantFichier.CARTE_IDENTITE,
            ETypeApprenantFichier.CHARTE_ENGAGEMENT,
            ETypeApprenantFichier.DOSSIER_SCOLAIRE,
            ETypeApprenantFichier.FICHE_FSE,
            ETypeApprenantFichier.PF_ENTREPRISE,
            ETypeApprenantFichier.ROI_CARNET_PEDA
          ]
        }
      }),
    [api, idapprenant]
  );

  const { search, loading } = useSearchApi<any, any>({
    searchFunction: searchFunc,
    tableState,
    initialSearch: true
  });

  const { subscribeToEvent, unsubscribeEvent } = useEventsContext();
  React.useEffect(() => {
    subscribeToEvent("SEARCH_TABLE_REFRESH", search);
    return () => unsubscribeEvent("SEARCH_TABLE_REFRESH", search);
  }, [search, subscribeToEvent, unsubscribeEvent]);

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: ApprenantFichierGridDto) => (
          <ButtonContainer>
            <EditButton
              minimal={true}
              onClick={() => setCurrentApprenantFichierId(row.idapprenantFichier)}
              loading={rowLoading === row.idapprenantFichier}
              disabled={!!rowLoading && rowLoading !== row.idapprenantFichier}
            />
            <DeleteButton
              minimal={true}
              onDelete={() => deleteFichier(row.idapprenantFichier)}
              loading={rowLoading === row.idapprenantFichier}
              disabled={!!rowLoading && rowLoading !== row.idapprenantFichier}
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Type),
        fieldName: "typeApprenantFichier"
      },
      {
        header: () => t(ETLCodes.Nom),
        fieldName: "nom"
      },
      {
        header: () => t(ETLCodes.DateDebut),
        fieldName: "dateDebut"
      },
      {
        header: () => t(ETLCodes.DateFin),
        fieldName: "dateFin"
      },
      {
        header: () => t(ETLCodes.Description),
        fieldName: "description"
      },
      {
        header: () => t(ETLCodes.DateUpload),
        fieldName: "uploadDate"
      },
      {
        header: () => t(ETLCodes.UtilisateurUpload),
        fieldName: "creationUserName"
      },
      {
        header: () => t(ETLCodes.Document),
        fieldName: "fileName",
        render: (row: ApprenantFichierGridDto) => (
          <LinkButton
            text={row.fileName}
            loading={rowLoading === row.idapprenantFichier}
            onClick={() => downloadFichier(row.idapprenantFichier)}
            rightIcon="download"
          />
        )
      }
    ],
    [deleteFichier, downloadFichier, rowLoading, setCurrentApprenantFichierId, t]
  );

  return (
    <FieldSet
      title={`${t(ETLCodes.AutresDocuments)}`}
      rightElement={
        <>
          <AddButton
            onClick={e => {
              e.stopPropagation();
              onAdd();
            }}
            text={t(ETLCodes.General_Add)}
            intent={Intent.PRIMARY}
          />
        </>
      }
    >
      <DataTable dateFormat="dd-MM-yyyy" tableState={tableState} loading={loading} columns={columns} />
    </FieldSet>
  );
};
