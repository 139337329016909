import { showError } from "nsitools-react";
import * as React from "react";
import { useParams } from "react-router";
import styled from "styled-components";

import { ApprenantAutresDocuments, ApprenantDocumentEditDialog, ApprenantDocumentUploadDialog } from ".";
import { ApprenantDocumentsReglementaires } from "..";
import { ApprenantFichierApi, ETypeApprenantFichier } from "../../../../../api";
import { useDialog, useEventsContext } from "../../../../../contexts";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import { exportFile } from "../../../../../utils";
import { ApprenantAvisAttestPFDocuments } from "./ApprenantAvisAttestPFDocuments";

export type ApprenantDocumentDialogMode = "REGLEMENTAIRES" | "AVIS_ATTEST_PF" | "AUTRES";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export interface IApprenantDocumentsProps {}

export const ApprenantDocuments: React.FunctionComponent<IApprenantDocumentsProps> = props => {
  const { t } = useTl();
  const api = useApiService(ApprenantFichierApi);
  const { id } = useParams<{ id: string }>();
  const [currentApprenantFichierId, setCurrentApprenantFichierId] = React.useState(null);
  const [uploadDialogOpen, setUploadDialogOpen] = React.useState(false);
  const [uploadDialogMode, setUploadDialogMode] = React.useState<ApprenantDocumentDialogMode>(null);
  const [selectedTypeApprenantFichier, setSelectedTypeApprenantFichier] = React.useState(null);
  const [rowLoading, setRowLoading] = React.useState(null);

  const { dispatchEvent } = useEventsContext();
  const { showDialogPromise } = useDialog();
  const deleteFichier = React.useCallback(
    async (idapprenantFichier: number) => {
      const result = await showDialogPromise({
        message: t(ETLCodes.DeleteConfirmationMessage)
      });

      if (result === "yes") {
        try {
          setRowLoading(idapprenantFichier);
          await api.apprenantFichierDeleteApprenantFichier({ id: idapprenantFichier });
          dispatchEvent("SEARCH_TABLE_REFRESH");
        } catch {
          showError(t(ETLCodes.ErrorWhileDeletingFile));
        } finally {
          setRowLoading(null);
        }
      }
    },
    [api, dispatchEvent, showDialogPromise, t]
  );

  const deleteAvisResultat = React.useCallback(
    async (idapprenantFichier: number) => {
      const result = await showDialogPromise({
        message: t(ETLCodes.DeleteConfirmationMessage)
      });

      if (result === "yes") {
        try {
          setRowLoading(idapprenantFichier);
          await api.apprenantFichierDeleteAvisResultat({ id: idapprenantFichier });
          dispatchEvent("SEARCH_TABLE_REFRESH");
        } catch {
          showError(t(ETLCodes.ErrorWhileDeletingFile));
        } finally {
          setRowLoading(null);
        }
      }
    },
    [api, dispatchEvent, showDialogPromise, t]
  );

  const downloadFichier = React.useCallback(
    async (idapprenantFichier: number) => {
      try {
        setRowLoading(idapprenantFichier);
        const file = await api.apprenantFichierDownloadApprenantFichier({ id: idapprenantFichier });
        await exportFile(file);
      } catch {
        showError(t(ETLCodes.ErrorWhileDownloadingFile));
      } finally {
        setRowLoading(null);
      }
    },
    [api, t]
  );

  const onClose = React.useCallback(
    (refresh?: boolean) => {
      setCurrentApprenantFichierId(null);
      setUploadDialogOpen(false);
      setUploadDialogMode(null);
      setSelectedTypeApprenantFichier(null);
      if (refresh) {
        dispatchEvent("SEARCH_TABLE_REFRESH");
      }
    },
    [dispatchEvent]
  );

  const onAdd = React.useCallback((mode: ApprenantDocumentDialogMode, type?: string) => {
    setUploadDialogOpen(true);
    setUploadDialogMode(mode);

    setSelectedTypeApprenantFichier(type);
  }, []);

  const excludedTypes = React.useMemo(() => {
    switch (uploadDialogMode) {
      case "REGLEMENTAIRES":
        return [
          ETypeApprenantFichier.JUSTIFICATIF,
          ETypeApprenantFichier.DOSSIER_SCOLAIRE_ACCUSE_FIC,
          ETypeApprenantFichier.CERTIFICAT_MEDICAL,
          ETypeApprenantFichier.AVIS_RESULTATS,
          ETypeApprenantFichier.AVIS_RESULTATS_EXTERNE,
          ETypeApprenantFichier.AVIS_RESULTATS_1ERE_SESSION,
          ETypeApprenantFichier.AVIS_RESULTATS_2EME_SESSION,
          ETypeApprenantFichier.PF_ENTREPRISE,
          ETypeApprenantFichier.ATTESTATION_REUSSITE,
          ETypeApprenantFichier.PPD_AUTORISATION_PARENTALE,
          ETypeApprenantFichier.PPD_CONSENTEMENT_COLLECTE,
          ETypeApprenantFichier.AUTRES
        ];
      case "AVIS_ATTEST_PF":
        return [
          ETypeApprenantFichier.JUSTIFICATIF,
          ETypeApprenantFichier.DOSSIER_SCOLAIRE_ACCUSE_FIC,
          ETypeApprenantFichier.CERTIFICAT_MEDICAL,
          ETypeApprenantFichier.CARTE_IDENTITE,
          ETypeApprenantFichier.CHARTE_ENGAGEMENT,
          ETypeApprenantFichier.DOSSIER_SCOLAIRE,
          ETypeApprenantFichier.FICHE_FSE,
          ETypeApprenantFichier.ROI_CARNET_PEDA,
          ETypeApprenantFichier.PPD_AUTORISATION_PARENTALE,
          ETypeApprenantFichier.PPD_CONSENTEMENT_COLLECTE,
          ETypeApprenantFichier.AUTRES
        ];
      case "AUTRES":
        return [
          ETypeApprenantFichier.AVIS_RESULTATS,
          ETypeApprenantFichier.AVIS_RESULTATS_EXTERNE,
          ETypeApprenantFichier.AVIS_RESULTATS_1ERE_SESSION,
          ETypeApprenantFichier.AVIS_RESULTATS_2EME_SESSION,
          ETypeApprenantFichier.CARTE_IDENTITE,
          ETypeApprenantFichier.CHARTE_ENGAGEMENT,
          ETypeApprenantFichier.DOSSIER_SCOLAIRE,
          ETypeApprenantFichier.FICHE_FSE,
          ETypeApprenantFichier.PF_ENTREPRISE,
          ETypeApprenantFichier.ROI_CARNET_PEDA,
          ETypeApprenantFichier.ATTESTATION_REUSSITE
        ];
      default:
        return [];
    }
  }, [uploadDialogMode]);

  return (
    <Container>
      <ApprenantDocumentsReglementaires
        deleteFichier={deleteFichier}
        downloadFichier={downloadFichier}
        idapprenant={+id}
        onAdd={(typeApprenantFichier: string) => onAdd("REGLEMENTAIRES", typeApprenantFichier)}
        rowLoading={rowLoading}
        setCurrentApprenantFichierId={id => {
          setCurrentApprenantFichierId(id);
          setUploadDialogMode("REGLEMENTAIRES");
        }}
      />
      <ApprenantAvisAttestPFDocuments
        deleteFichier={deleteFichier}
        deleteAvisResultat={deleteAvisResultat}
        downloadFichier={downloadFichier}
        idapprenant={+id}
        onAdd={() => onAdd("AVIS_ATTEST_PF")}
        rowLoading={rowLoading}
        setCurrentApprenantFichierId={id => {
          setCurrentApprenantFichierId(id);
          setUploadDialogMode("AVIS_ATTEST_PF");
        }}
      />
      <ApprenantAutresDocuments
        deleteFichier={deleteFichier}
        downloadFichier={downloadFichier}
        idapprenant={+id}
        onAdd={() => onAdd("AUTRES")}
        rowLoading={rowLoading}
        setCurrentApprenantFichierId={id => {
          setCurrentApprenantFichierId(id);
          setUploadDialogMode("AUTRES");
        }}
      />
      {uploadDialogOpen && (
        <ApprenantDocumentUploadDialog
          onClose={onClose}
          dialogOpen={uploadDialogOpen}
          idapprenant={+id}
          excludedTypes={excludedTypes}
          mode={uploadDialogMode}
          typeApprenantFichier={selectedTypeApprenantFichier}
        />
      )}
      {!!currentApprenantFichierId && (
        <ApprenantDocumentEditDialog
          onClose={onClose}
          idapprenantFichier={currentApprenantFichierId}
          excludedTypes={excludedTypes}
          mode={uploadDialogMode}
          idapprenant={+id}
        />
      )}
    </Container>
  );
};
