import { FGTextInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";
import * as Yup from "yup";

import { AcquisIfapmeApi, AcquisIfapmeEditDtoFromJSON } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { FGWalterCheckboxInput, SmallFormGenerator } from "../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface IAcquisIfapmeDetailPageProps {
  editMode: boolean;
  id: number;
}

export const AcquisIfapmeDetailPage: React.FunctionComponent<IAcquisIfapmeDetailPageProps> = ({ editMode, id }) => {
  const { t } = useTl();
  const api = useApiService(AcquisIfapmeApi);
  const history = useHistory();

  const { data, loading, saveItem, saving, validationErrors } = useCrudApi({
    getApiFn: () =>
      id === 0 ? AcquisIfapmeEditDtoFromJSON({ idtitreDiplomeCertificationIfapme: 0 }) : api.acquisIfapmeGet({ id }),
    saveApiFn: d => api.acquisIfapmeSave({ AcquisIfapmeEditDto: d }),
    onSavedRoute: d => `${ERoutes.acquisIfapme}/${d.idtitreDiplomeCertificationIfapme}/detail/edit`,
    deleteApiFn: d => api.acquisIfapmeDelete({ id: d.idtitreDiplomeCertificationIfapme }),
    onDeletedRoute: () => `${ERoutes.acquisIfapme}/`
  });

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      description: Yup.string().required(t(ETLCodes.Required)),
      actif: Yup.boolean().required(t(ETLCodes.Required))
    });
  }, [t]);

  return (
    <SmallFormGenerator
      editable={false}
      initialValues={data}
      onSubmit={saveItem}
      editMode={editMode}
      validationSchema={FormSchema}
      loading={loading}
      onCancel={() => history.push(ERoutes.acquisIfapme)}
      showDeleteButton={false}
      saving={saving}
      specificRights={{ module: "ANNEESCOLAIRE", tab: "DETAIL", rights: "RW" }}
      validationErrors={validationErrors}
    >
      <FieldGroup columns={2}>
        <FieldGroup>
          <FGTextInput name="description" label={t(ETLCodes.Description)} />
          <FGWalterCheckboxInput name="actif" label={t(ETLCodes.Actif)} />
        </FieldGroup>
      </FieldGroup>
    </SmallFormGenerator>
  );
};
