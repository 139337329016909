import { Classes, Dialog } from "@blueprintjs/core";
import { FGCheckboxInput } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";
import * as Yup from "yup";

import { FGWalterDateMaskInput, FGWalterSelectInput, SmallFormGenerator } from "..";
import { StatutApprenantApi, StatutApprenantEditDto } from "../../api";
import { useApiService, useCrudApi, useTl } from "../../hooks";
import { useReferential } from "../../hooks/useReferential";
import { ETLCodes } from "../../locales";

const StyledDialog = styled(Dialog)`
  width: 600px;
  height: auto;
  background-color: white;
`;

export interface IAddStatutPostSortieDialogProps {
  idApprenant: number;
  onClosed: (refresh: boolean) => void;
  isDialogOpen: boolean;
}

export const AddStatutPostSortieDialog: React.FunctionComponent<IAddStatutPostSortieDialogProps> = ({
  idApprenant,
  isDialogOpen,
  onClosed
}) => {
  const { t } = useTl();
  const api = useApiService(StatutApprenantApi);
  const [idCatStatutSocial, setIdCatStatutSocial] = React.useState(0);

  const { data, loading, saveItem, saving } = useCrudApi<StatutApprenantEditDto>(
    React.useMemo(
      () => ({
        getApiFn: async () => {
          return await api.statutApprenantGetNewStatutPostSortie({ idapprenant: idApprenant });
        },
        saveApiFn: d => api.statutApprenantSaveStatutApprenant({ StatutApprenantEditDto: d }),
        onSaved: () => onClosed(true),
        getDeps: [idApprenant]
      }),
      [idApprenant, api, onClosed]
    )
  );

  const [categoriesStatutSocial, cssLoading] = useReferential(
    a => a.referentialGetCategoriesStatutSocial({ currentIdcategorieStatutSocial: idCatStatutSocial }),
    true,
    [idCatStatutSocial]
  );
  const [idstatutSocial, setIdstatutSocial] = React.useState(0);
  const [statutsSocial, ssLoading] = useReferential(
    a =>
      a.referentialGetSousStatutsByCategorie({ idCategorie: idCatStatutSocial, currentIdstatutSocial: idstatutSocial }),
    true,
    [idCatStatutSocial, idstatutSocial]
  );

  const FormSchema = React.useMemo(
    () =>
      Yup.object().shape({
        idCategorieStatut: Yup.number()
          .nullable()
          .required(t(ETLCodes.Required)),
        idStatut: Yup.number()
          .nullable()
          .required(t(ETLCodes.Required)),
        dateStatut: Yup.date()
          .nullable()
          .required(t(ETLCodes.Required))
      }),
    [t]
  );

  return (
    <StyledDialog title={t(ETLCodes.NouveauStatutPostFormation)} isOpen={isDialogOpen} onClose={() => onClosed(false)}>
      <div className={Classes.DIALOG_BODY}>
        <SmallFormGenerator
          initialValues={data}
          onSubmit={saveItem}
          editMode={true}
          validationSchema={FormSchema}
          loading={loading}
          onCancel={() => onClosed(false)}
          showDeleteButton={false}
          saving={saving}
          forceEnableSave
          watchChanges={{
            idStatut: setIdstatutSocial,
            idCategorieStatut: setIdCatStatutSocial
          }}
        >
          <FGWalterSelectInput
            name="idCategorieStatut"
            label={t(ETLCodes.CategorieStatut)}
            items={categoriesStatutSocial}
            loading={cssLoading}
          />
          <FGWalterSelectInput name="idStatut" label={t(ETLCodes.Statut)} items={statutsSocial} loading={ssLoading} />
          <FGWalterDateMaskInput name="dateStatut" label={t(ETLCodes.DateStatut)} />
          <FGCheckboxInput name="suiviTermine" label={t(ETLCodes.SuiviTermine)} disabled={!!data?.suiviTermine} />
        </SmallFormGenerator>
      </div>
    </StyledDialog>
  );
};
