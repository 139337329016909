import { BilanCompetenceDetail } from "./BilanCompetenceDetail";
import { ExamenEntreeFceDetail } from "./ExamenEntreeFceDetail";
import { DossierAdmissionDetail } from "./DossierAdmissionDetail";
import { ExamenEntreeHopeDetail } from "./ExamenEntreeHopeDetail";
import { ExamenEntreeSpecifiqueDetail } from "./ExamenEntreeSpecifiqueDetail";
import * as React from "react";
export interface IAcquisAdmissionGlobalProps {
  idpersonne: number;
  listRoute: string;
}

export const AcquisAdmissionGlobal: React.FunctionComponent<IAcquisAdmissionGlobalProps> = ({
  idpersonne,
  listRoute
}) => {
  const [typeAcquisForm, setTypeAcquisForm] = React.useState("ExamenEntree");
  const changeFormType = React.useMemo(() => {
    switch (typeAcquisForm) {
      case "BilanCompetence":
        return (
          <BilanCompetenceDetail onTypeAcquisChange={setTypeAcquisForm} idpersonne={idpersonne} listRoute={listRoute} />
        );
      case "ExamenEntreeFCE":
        return (
          <ExamenEntreeFceDetail onTypeAcquisChange={setTypeAcquisForm} idpersonne={idpersonne} listRoute={listRoute} />
        );
      case "ExamenEntree":
        return (
          <ExamenEntreeHopeDetail
            onTypeAcquisChange={setTypeAcquisForm}
            idpersonne={idpersonne}
            listRoute={listRoute}
          />
        );
      case "ExamenEntreeSpecifique":
        return (
          <ExamenEntreeSpecifiqueDetail
            onTypeAcquisChange={setTypeAcquisForm}
            idpersonne={idpersonne}
            listRoute={listRoute}
          />
        );
      case "DossierAdmission":
        return (
          <DossierAdmissionDetail
            onTypeAcquisChange={setTypeAcquisForm}
            idpersonne={idpersonne}
            listRoute={listRoute}
          />
        );
      default:
        return (
          <BilanCompetenceDetail onTypeAcquisChange={setTypeAcquisForm} idpersonne={idpersonne} listRoute={listRoute} />
        );
    }
  }, [idpersonne, listRoute, typeAcquisForm]);

  return <>{changeFormType}</>;
};
