import { Intent } from "@blueprintjs/core";
import { addMinutes, format, parseJSON } from "date-fns";
import {
  ButtonContainer,
  DataTable,
  FGListen,
  FGNumberInput,
  FGTextAreaInput,
  FieldGroup,
  IDataTableColumn,
  showError,
  useGridState
} from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";

import { ExamenEntreeDocumentEditDialog, ExamenEntreeDocumentUploadDialog } from ".";
import {
  AcquisAdmissionApi,
  ELevelName,
  ExamenEntreeEditDto,
  ExamenEntreeEditDtoFromJSON,
  ExamenEntreeFichierGridDto
} from "../../../../../../api";
import {
  AddButton,
  DeleteButton,
  DownloadButton,
  EditButton,
  FGWalterDateMaskInput,
  FGWalterSelectInput,
  SmallFormGenerator
} from "../../../../../../components";
import { useDialog } from "../../../../../../contexts";
import { useApiService, useCrudApi, useTl } from "../../../../../../hooks";
import { useReferential } from "../../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../../locales";
import { exportFile } from "../../../../../../utils";

export interface IExamenEntreeSpecifiqueDetailProps {
  onTypeAcquisChange?: (typeAcquis: string) => void;
  idpersonne: number;
  listRoute: string;
}

export const ExamenEntreeSpecifiqueDetail: React.FunctionComponent<IExamenEntreeSpecifiqueDetailProps> = ({
  onTypeAcquisChange,
  idpersonne,
  listRoute
}) => {
  const { t } = useTl();
  const api = useApiService(AcquisAdmissionApi);
  const [typeAcquis, taLoading] = useReferential(a => a.referentialGetAcquisAdmissionTypes(), false, []);
  const [acquisCom, acLoading] = useReferential(a => a.referentialGetAcquisCommentaires(), true, []);
  const [anneeFormation, afLoading] = useReferential(a => a.referentialGetAnneeScolaire(), false, []);
  const [resultats, resuLoading] = useReferential(a => a.referentialGetResultatExamenEntree(), true, []);
  const [examinateurs, eLoading] = useReferential(a =>
    a.referentialGetUsersByRoles({ ELevelName: [ELevelName.COP], currentIduser: 0 })
  );
  const history = useHistory();
  const { state, acquisId } = useParams<{
    id: string;
    tab: string;
    state: string;
    acquisId: string;
  }>();
  const editMode = React.useMemo(() => state === "edit", [state]);

  const { data, loading, saveItem, saving, deleteItem, deleting, validationErrors, refresh: refreshData } = useCrudApi<
    ExamenEntreeEditDto
  >(
    React.useMemo(
      () => ({
        getApiFn: async () => {
          return +acquisId <= 0
            ? ExamenEntreeEditDtoFromJSON({
                idexamenEntree: +acquisId,
                idpersonne: idpersonne,
                typeAcquis: "ExamenEntreeSpecifique",
                specifique: true
              })
            : api.acquisAdmissionGetExamenEntree({ id: +acquisId });
        },
        saveApiFn: d => api.acquisAdmissionSaveExamenEntree({ ExamenEntreeEditDto: d }),
        onSavedRoute: d => listRoute,
        getDeps: [acquisId],
        deleteApiFn: d => api.acquisAdmissionDeleteExamenEntree({ id: d.idexamenEntree }),
        onDeletedRoute: () => listRoute
      }),
      [acquisId, idpersonne, api, listRoute]
    )
  );
  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      typeAcquis: Yup.string().required(t(ETLCodes.Required)),
      date: Yup.date().typeError(t(ETLCodes.DateInvalide))
    });
  }, [t]);

  const tableState = useGridState<any>({
    serverMode: false,
    enablePagination: true,
    enableFilter: false,
    availablePageSizes: [15, 25, 50],
    pageSize: 15,
    sortKeys: { uploadDate: "DESC", fileName: "ASC" }
  });

  const { setData } = tableState;

  React.useEffect(() => {
    if (data?.fichiers) {
      setData(data.fichiers);
    }
  }, [data?.fichiers, setData]);

  const [currentExamenEntreeFichierId, setCurrentExamenEntreeFichierId] = React.useState(null);
  const [uploadDialoOpen, setUploadDialoOpen] = React.useState(false);
  const [rowLoading, setRowLoading] = React.useState(null);

  const { showDialogPromise } = useDialog();
  const deleteFichier = React.useCallback(
    async (idexamenEntreeFichier: number) => {
      const result = await showDialogPromise({
        message: t(ETLCodes.DeleteConfirmationMessage)
      });

      if (result === "yes") {
        try {
          setRowLoading(idexamenEntreeFichier);
          await api.acquisAdmissionDeleteExamenEntreeFichier({ id: idexamenEntreeFichier });
          refreshData();
        } catch {
          showError(t(ETLCodes.ErrorWhileDeletingFile));
        } finally {
          setRowLoading(null);
        }
      }
    },
    [api, refreshData, showDialogPromise, t]
  );

  const downloadFichier = React.useCallback(
    async (idexamenEntreeFichier: number) => {
      try {
        setRowLoading(idexamenEntreeFichier);
        const file = await api.acquisAdmissionDownloadExamenEntreeFichier({ id: idexamenEntreeFichier });
        await exportFile(file);
      } catch {
        showError(t(ETLCodes.ErrorWhileDownloadingFile));
      } finally {
        setRowLoading(null);
      }
    },
    [api, t]
  );

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: ExamenEntreeFichierGridDto) => (
          <ButtonContainer>
            <EditButton
              minimal={true}
              onClick={() => setCurrentExamenEntreeFichierId(row.idexamenEntreeFichier)}
              loading={rowLoading === row.idexamenEntreeFichier}
              disabled={!!rowLoading && rowLoading !== row.idexamenEntreeFichier}
            />
            <DownloadButton
              minimal={true}
              onClick={() => downloadFichier(row.idexamenEntreeFichier)}
              loading={rowLoading === row.idexamenEntreeFichier}
              disabled={!!rowLoading && rowLoading !== row.idexamenEntreeFichier}
            />
            <DeleteButton
              minimal={true}
              onDelete={() => deleteFichier(row.idexamenEntreeFichier)}
              loading={rowLoading === row.idexamenEntreeFichier}
              disabled={!!rowLoading && rowLoading !== row.idexamenEntreeFichier}
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.NomFichier),
        fieldName: "fileName"
      },
      {
        header: () => t(ETLCodes.DateUpload),
        fieldName: "uploadDate"
      },
      {
        header: () => t(ETLCodes.UtilisateurUpload),
        fieldName: "creationUserName"
      },
      {
        header: () => t(ETLCodes.Type),
        fieldName: "typeAcquisFichier"
      }
    ],
    [deleteFichier, downloadFichier, rowLoading, t]
  );

  const formatDate = React.useCallback((date: Date) => {
    return format(parseJSON(addMinutes(date, date.getTimezoneOffset())), "dd/MM/yyyy HH:mm");
  }, []);

  const onClose = React.useCallback(
    (refresh?: boolean) => {
      setCurrentExamenEntreeFichierId(null);
      setUploadDialoOpen(false);
      if (refresh) {
        refreshData();
      }
    },
    [refreshData]
  );

  const onAdd = React.useCallback(() => {
    setUploadDialoOpen(true);
  }, []);

  return (
    <SmallFormGenerator
      initialValues={data}
      onSubmit={saveItem}
      showColons
      editMode={editMode}
      minLabelWidth={180}
      labelAlignment="right"
      formatDate="dd/MM/yyyy"
      validationSchema={FormSchema}
      loading={loading}
      onCancel={() => history.push(listRoute)}
      saving={saving}
      showDeleteButton={+acquisId > 0}
      onDelete={deleteItem}
      deleting={deleting}
      validationErrors={validationErrors}
    >
      <FieldGroup columns={2}>
        <FieldGroup
          fieldsetProps={{
            title: t(ETLCodes.ExamenEntree)
          }}
        >
          <FGListen field="typeAcquis" onChanged={onTypeAcquisChange}></FGListen>
          <FGWalterSelectInput
            label={t(ETLCodes.Type)}
            name="typeAcquis"
            items={typeAcquis}
            loading={taLoading}
            readonly={+(acquisId ?? 0) > 0}
          />
          <FGWalterSelectInput
            label={t(ETLCodes.Annee)}
            name="idanneeDeFormation"
            items={anneeFormation}
            loading={afLoading}
          />
          <FGWalterDateMaskInput label={t(ETLCodes.Date)} name="date" />
          <FGNumberInput label={t(ETLCodes.PointsMathematiques)} name="pointsmath" />
          <FGNumberInput label={t(ETLCodes.PointsFrancais)} name="pointsfrancais" />
          <FGNumberInput label={t(ETLCodes.PointsLogique)} name="pointslogique" />
          <FGWalterSelectInput
            label={t(ETLCodes.ResultatFinal)}
            name="resultat"
            items={resultats}
            loading={resuLoading}
          />
          <FGWalterSelectInput
            label={t(ETLCodes.Examinateur)}
            name="examinateur"
            items={examinateurs}
            loading={eLoading}
          />
          <FGWalterSelectInput
            label={t(ETLCodes.Commentaire)}
            name="commentaire"
            items={acquisCom}
            loading={acLoading}
          />
          <FGTextAreaInput label={t(ETLCodes.Remarque)} name="remarque" />
        </FieldGroup>
      </FieldGroup>
      <FieldGroup
        visible={+acquisId > 0}
        fieldsetProps={{
          title: t(ETLCodes.Documents),
          rightElement: (
            <AddButton
              onClick={e => {
                e.stopPropagation();
                onAdd();
              }}
              text={t(ETLCodes.General_Add)}
              intent={Intent.PRIMARY}
            />
          )
        }}
      >
        <DataTable
          dateFormat="dd/MM/yyyy HH:mm"
          formatDate={formatDate}
          tableState={tableState}
          loading={loading}
          columns={columns}
        ></DataTable>
        {uploadDialoOpen && (
          <ExamenEntreeDocumentUploadDialog onClose={onClose} dialogOpen={uploadDialoOpen} idexamenEntree={+acquisId} />
        )}
        {!!currentExamenEntreeFichierId && (
          <ExamenEntreeDocumentEditDialog onClose={onClose} idexamenEntreeFichier={currentExamenEntreeFichierId} />
        )}
      </FieldGroup>
    </SmallFormGenerator>
  );
};
