/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EErrorCode } from './EErrorCode';
import {
    EErrorCodeFromJSON,
    EErrorCodeFromJSONTyped,
    EErrorCodeToJSON,
} from './EErrorCode';

/**
 * 
 * @export
 * @interface InscriptionSuspensionMultipleDto
 */
export interface InscriptionSuspensionMultipleDto {
    /**
     * 
     * @type {Array<number>}
     * @memberof InscriptionSuspensionMultipleDto
     */
    idsinscription?: Array<number> | null;
    /**
     * 
     * @type {Date}
     * @memberof InscriptionSuspensionMultipleDto
     */
    dateDebut?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof InscriptionSuspensionMultipleDto
     */
    dateFin?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof InscriptionSuspensionMultipleDto
     */
    remarque?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InscriptionSuspensionMultipleDto
     */
    endMode?: boolean;
    /**
     * 
     * @type {{ [key: string]: EErrorCode; }}
     * @memberof InscriptionSuspensionMultipleDto
     */
    errors?: { [key: string]: EErrorCode; } | null;
}

/**
 * Check if a given object implements the InscriptionSuspensionMultipleDto interface.
 */
export function instanceOfInscriptionSuspensionMultipleDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InscriptionSuspensionMultipleDtoFromJSON(json: any): InscriptionSuspensionMultipleDto {
    return InscriptionSuspensionMultipleDtoFromJSONTyped(json, false);
}

export function InscriptionSuspensionMultipleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InscriptionSuspensionMultipleDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idsinscription': !exists(json, 'idsinscription') ? undefined : json['idsinscription'],
        'dateDebut': !exists(json, 'dateDebut') ? undefined : (json['dateDebut'] === null ? null : new Date(json['dateDebut'])),
        'dateFin': !exists(json, 'dateFin') ? undefined : (json['dateFin'] === null ? null : new Date(json['dateFin'])),
        'remarque': !exists(json, 'remarque') ? undefined : json['remarque'],
        'endMode': !exists(json, 'endMode') ? undefined : json['endMode'],
        'errors': !exists(json, 'errors') ? undefined : (json['errors'] === null ? null : mapValues(json['errors'], EErrorCodeFromJSON)),
    };
}

export function InscriptionSuspensionMultipleDtoToJSON(value?: InscriptionSuspensionMultipleDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idsinscription': value.idsinscription,
        'dateDebut': value.dateDebut === undefined ? undefined : (value.dateDebut === null ? null : value.dateDebut.toISOString()),
        'dateFin': value.dateFin === undefined ? undefined : (value.dateFin === null ? null : value.dateFin.toISOString()),
        'remarque': value.remarque,
        'endMode': value.endMode,
        'errors': value.errors === undefined ? undefined : (value.errors === null ? null : mapValues(value.errors, EErrorCodeToJSON)),
    };
}

