import { showError } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import { ApprenantSuiviForm } from "..";
import { EContentHidingMode, FcbSuiviApprenantDetailsDtoFromJSON, SuiviApprenantApi } from "../../../../../api";
import { useAuth, useDialog } from "../../../../../contexts";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import { exportFile } from "../../../../../utils";

export interface IApprenantSuiviDetailPageProps {
  baseRoute: string;
}

export const ApprenantSuiviDetailPage: React.FunctionComponent<IApprenantSuiviDetailPageProps> = ({ baseRoute }) => {
  const { t } = useTl();
  const api = useApiService(SuiviApprenantApi);
  const { id, tab, idSuivi, state } = useParams<{ id: string; tab: string; idSuivi: string; state: string }>();
  const editMode = React.useMemo(() => state === "edit", [state]);
  const idApprenant = React.useMemo(() => +id, [id]);
  const IdSuivi = React.useMemo(() => +idSuivi, [idSuivi]);
  const auth = useAuth();
  const history = useHistory();

  const { data, loading, deleteItem, deleting, saveItem, saving, validationErrors, refresh } = useCrudApi({
    getApiFn: () =>
      IdSuivi <= 0
        ? FcbSuiviApprenantDetailsDtoFromJSON({
            idsuivi: 0,
            idrealisateurSuivi: auth.user.iduser,
            idapprenant: idApprenant,
            utilisateur: `${auth?.user.nom} ${auth?.user.prenom}`,
            dateSuivi: new Date(),
            contentHidingMode: EContentHidingMode.NONE
          })
        : api.suiviApprenantGet({ id: IdSuivi }),
    saveApiFn: d => api.suiviApprenantSave({ FcbSuiviApprenantDetailsDto: d }),
    onSavedRoute: d => `${baseRoute}/${idApprenant}/${tab}/${d.idsuivi}/${state}/walter`,
    deleteApiFn: d => api.suiviApprenantDelete({ id: d.idsuivi }),
    onDeletedRoute: d => `${baseRoute}/${idApprenant}/${tab}/${state}`
  });

  const onCancel = React.useCallback(() => history.push(`${baseRoute}/${id}/${tab}/${state}`), [
    baseRoute,
    history,
    id,
    state,
    tab
  ]);

  const [rowLoading, setRowLoading] = React.useState(null);
  const { showDialogPromise } = useDialog();
  const deleteFichier = React.useCallback(
    async (idsuiviFichier: number) => {
      const result = await showDialogPromise({
        message: t(ETLCodes.DeleteConfirmationMessage)
      });

      if (result === "yes") {
        try {
          setRowLoading(idsuiviFichier);
          await api.suiviApprenantDeleteSuiviApprenantFichier({
            id: idsuiviFichier
          });
          refresh();
        } catch {
          showError(t(ETLCodes.ErrorWhileDeletingFile));
        } finally {
          setRowLoading(null);
        }
      }
    },
    [api, refresh, showDialogPromise, t]
  );

  const downloadFichier = React.useCallback(
    async (idsuiviFichier: number) => {
      try {
        setRowLoading(idsuiviFichier);
        const file = await api.suiviApprenantDownloadSuiviApprenantFichier({
          id: idsuiviFichier
        });
        await exportFile(file);
      } catch {
        showError(t(ETLCodes.ErrorWhileDownloadingFile));
      } finally {
        setRowLoading(null);
      }
    },
    [api, t]
  );

  return (
    <ApprenantSuiviForm
      data={data}
      suiviId={+IdSuivi}
      saveItem={saveItem}
      loading={loading}
      saving={saving}
      editMode={editMode && !data?.fromSmile}
      deleteItem={deleteItem}
      deleting={deleting}
      onCancel={onCancel}
      validationErrors={validationErrors}
      fieldsetTitle={t(ETLCodes.Suivi)}
      deleteFichier={deleteFichier}
      downloadFichier={downloadFichier}
      rowLoading={rowLoading}
      refreshData={refresh}
    />
  );
};
