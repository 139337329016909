/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ETypeDernierAvertissement,
  FileDownloadDto,
  FilterCriteriaInfo,
  ReferentialItemDto,
  SuiviFichierDto,
  SuiviFichierUploadDto,
  SuiviGlobalSearchDto,
  SuiviGridDtoPaginatedResults,
  SuiviHopeEditDto,
  SuiviHopeMultipleEditDto,
  SuiviSearchDto,
  SuiviWalterMultipleEditDto,
} from '../models/index';
import {
    ETypeDernierAvertissementFromJSON,
    ETypeDernierAvertissementToJSON,
    FileDownloadDtoFromJSON,
    FileDownloadDtoToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    ReferentialItemDtoFromJSON,
    ReferentialItemDtoToJSON,
    SuiviFichierDtoFromJSON,
    SuiviFichierDtoToJSON,
    SuiviFichierUploadDtoFromJSON,
    SuiviFichierUploadDtoToJSON,
    SuiviGlobalSearchDtoFromJSON,
    SuiviGlobalSearchDtoToJSON,
    SuiviGridDtoPaginatedResultsFromJSON,
    SuiviGridDtoPaginatedResultsToJSON,
    SuiviHopeEditDtoFromJSON,
    SuiviHopeEditDtoToJSON,
    SuiviHopeMultipleEditDtoFromJSON,
    SuiviHopeMultipleEditDtoToJSON,
    SuiviSearchDtoFromJSON,
    SuiviSearchDtoToJSON,
    SuiviWalterMultipleEditDtoFromJSON,
    SuiviWalterMultipleEditDtoToJSON,
} from '../models/index';

export interface SuiviBaseSearchRequest {
    SuiviGlobalSearchDto?: SuiviGlobalSearchDto;
}

export interface SuiviDeleteSuiviFichierRequest {
    id?: number;
}

export interface SuiviDeleteSuiviHopeRequest {
    id?: number;
}

export interface SuiviDownloadSuiviFichierRequest {
    id?: number;
}

export interface SuiviGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface SuiviGetSelectItemsRequest {
    searchField: string;
}

export interface SuiviGetSuiviFichierRequest {
    id?: number;
}

export interface SuiviGetSuiviHopeRequest {
    id?: number;
    idapprenant?: number;
    typeAvertissement?: ETypeDernierAvertissement;
}

export interface SuiviSaveSuiviFichierRequest {
    SuiviFichierDto?: SuiviFichierDto;
}

export interface SuiviSaveSuiviHopeRequest {
    SuiviHopeEditDto?: SuiviHopeEditDto;
}

export interface SuiviSaveSuiviHopeMultipleRequest {
    SuiviHopeMultipleEditDto?: SuiviHopeMultipleEditDto;
}

export interface SuiviSaveSuiviWalterMultipleRequest {
    SuiviWalterMultipleEditDto?: SuiviWalterMultipleEditDto;
}

export interface SuiviSearchSuiviHopeByApprenantRequest {
    SuiviSearchDto?: SuiviSearchDto;
}

export interface SuiviUploadSuiviFichierRequest {
    SuiviFichierUploadDto?: SuiviFichierUploadDto;
}

/**
 * 
 */
export class SuiviApi extends runtime.BaseAPI {

    /**
     */
    async suiviBaseSearchRaw(requestParameters: SuiviBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SuiviGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Suivi/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SuiviGlobalSearchDtoToJSON(requestParameters.SuiviGlobalSearchDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SuiviGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async suiviBaseSearch(requestParameters: SuiviBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SuiviGridDtoPaginatedResults> {
        const response = await this.suiviBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async suiviDeleteSuiviFichierRaw(requestParameters: SuiviDeleteSuiviFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Suivi/DeleteSuiviFichier`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async suiviDeleteSuiviFichier(requestParameters: SuiviDeleteSuiviFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.suiviDeleteSuiviFichierRaw(requestParameters, initOverrides);
    }

    /**
     */
    async suiviDeleteSuiviHopeRaw(requestParameters: SuiviDeleteSuiviHopeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Suivi/DeleteSuiviHope`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async suiviDeleteSuiviHope(requestParameters: SuiviDeleteSuiviHopeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.suiviDeleteSuiviHopeRaw(requestParameters, initOverrides);
    }

    /**
     */
    async suiviDownloadSuiviFichierRaw(requestParameters: SuiviDownloadSuiviFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileDownloadDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Suivi/DownloadSuiviFichier`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileDownloadDtoFromJSON(jsonValue));
    }

    /**
     */
    async suiviDownloadSuiviFichier(requestParameters: SuiviDownloadSuiviFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileDownloadDto> {
        const response = await this.suiviDownloadSuiviFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async suiviGetSearchCriteriasRaw(requestParameters: SuiviGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Suivi/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async suiviGetSearchCriterias(requestParameters: SuiviGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.suiviGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async suiviGetSelectItemsRaw(requestParameters: SuiviGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ReferentialItemDto>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling suiviGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Suivi/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReferentialItemDtoFromJSON));
    }

    /**
     */
    async suiviGetSelectItems(requestParameters: SuiviGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ReferentialItemDto>> {
        const response = await this.suiviGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async suiviGetSuiviFichierRaw(requestParameters: SuiviGetSuiviFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SuiviFichierDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Suivi/GetSuiviFichier`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SuiviFichierDtoFromJSON(jsonValue));
    }

    /**
     */
    async suiviGetSuiviFichier(requestParameters: SuiviGetSuiviFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SuiviFichierDto> {
        const response = await this.suiviGetSuiviFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async suiviGetSuiviHopeRaw(requestParameters: SuiviGetSuiviHopeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SuiviHopeEditDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.idapprenant !== undefined) {
            queryParameters['idapprenant'] = requestParameters.idapprenant;
        }

        if (requestParameters.typeAvertissement !== undefined) {
            queryParameters['typeAvertissement'] = requestParameters.typeAvertissement;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Suivi/GetSuiviHope`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SuiviHopeEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async suiviGetSuiviHope(requestParameters: SuiviGetSuiviHopeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SuiviHopeEditDto> {
        const response = await this.suiviGetSuiviHopeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async suiviSaveSuiviFichierRaw(requestParameters: SuiviSaveSuiviFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SuiviFichierDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Suivi/SaveSuiviFichier`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SuiviFichierDtoToJSON(requestParameters.SuiviFichierDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SuiviFichierDtoFromJSON(jsonValue));
    }

    /**
     */
    async suiviSaveSuiviFichier(requestParameters: SuiviSaveSuiviFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SuiviFichierDto> {
        const response = await this.suiviSaveSuiviFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async suiviSaveSuiviHopeRaw(requestParameters: SuiviSaveSuiviHopeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SuiviHopeEditDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Suivi/SaveSuiviHope`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SuiviHopeEditDtoToJSON(requestParameters.SuiviHopeEditDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SuiviHopeEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async suiviSaveSuiviHope(requestParameters: SuiviSaveSuiviHopeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SuiviHopeEditDto> {
        const response = await this.suiviSaveSuiviHopeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async suiviSaveSuiviHopeMultipleRaw(requestParameters: SuiviSaveSuiviHopeMultipleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SuiviHopeMultipleEditDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Suivi/SaveSuiviHopeMultiple`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SuiviHopeMultipleEditDtoToJSON(requestParameters.SuiviHopeMultipleEditDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SuiviHopeMultipleEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async suiviSaveSuiviHopeMultiple(requestParameters: SuiviSaveSuiviHopeMultipleRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SuiviHopeMultipleEditDto> {
        const response = await this.suiviSaveSuiviHopeMultipleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async suiviSaveSuiviWalterMultipleRaw(requestParameters: SuiviSaveSuiviWalterMultipleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SuiviWalterMultipleEditDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Suivi/SaveSuiviWalterMultiple`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SuiviWalterMultipleEditDtoToJSON(requestParameters.SuiviWalterMultipleEditDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SuiviWalterMultipleEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async suiviSaveSuiviWalterMultiple(requestParameters: SuiviSaveSuiviWalterMultipleRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SuiviWalterMultipleEditDto> {
        const response = await this.suiviSaveSuiviWalterMultipleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async suiviSearchSuiviHopeByApprenantRaw(requestParameters: SuiviSearchSuiviHopeByApprenantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SuiviGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Suivi/SearchSuiviHopeByApprenant`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SuiviSearchDtoToJSON(requestParameters.SuiviSearchDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SuiviGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async suiviSearchSuiviHopeByApprenant(requestParameters: SuiviSearchSuiviHopeByApprenantRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SuiviGridDtoPaginatedResults> {
        const response = await this.suiviSearchSuiviHopeByApprenantRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async suiviUploadSuiviFichierRaw(requestParameters: SuiviUploadSuiviFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SuiviFichierDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Suivi/UploadSuiviFichier`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SuiviFichierUploadDtoToJSON(requestParameters.SuiviFichierUploadDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SuiviFichierDtoFromJSON(jsonValue));
    }

    /**
     */
    async suiviUploadSuiviFichier(requestParameters: SuiviUploadSuiviFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SuiviFichierDto> {
        const response = await this.suiviUploadSuiviFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
