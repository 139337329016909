import { Button, Intent, Menu, MenuItem, Popover } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { ButtonContainer, FGCustomPanel, FGListen, FieldGroup, FieldSet } from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";

import { EStatutDemande, ParcoursFormationApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { FGWalterDateMaskInput, FGWalterSelectInput, SmallFormGenerator } from "../../../../../components";
import { AddParcoursFormationButton } from "../../../../../components/parcoursFormation/AddParcoursFormationButton";
import { SortieReseauButton } from "../../../../../components/parcoursFormation/SortieReseauButton";
import { useEventsContext } from "../../../../../contexts";
import { useApiService, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";
import { ParcoursFormationHistorique } from "./ParcoursFormationHistorique";
import { ParcoursInterneList } from "./ParcoursInterneList";

const PopoverContent = styled.div`
  padding: 0.5rem;
`;
export interface IParcoursFormationDetailPageProps {}

export const ParcoursFormationDetailPage: React.FunctionComponent<IParcoursFormationDetailPageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const { dispatchEvent } = useEventsContext();
  const { id, idParcoursFormation } = useParams<{
    id: string;
    state: string;
    idParcoursFormation: string;
  }>();
  const api = useApiService(ParcoursFormationApi);

  const idParcours = React.useMemo(() => (+idParcoursFormation > 0 ? +idParcoursFormation : 0), [idParcoursFormation]);

  const [idCatStatutSocial, setIdCatStatutSocial] = React.useState();
  const [categoriesStatutSocial, cssLoading] = useReferential(
    a => a.referentialGetCategoriesStatutSocial({ currentIdcategorieStatutSocial: idCatStatutSocial }),
    true,
    [idCatStatutSocial]
  );
  const [idstatutSocial, setIdstatutSocial] = React.useState(0);
  const [statutsSocial, ssLoading] = useReferential(
    a =>
      a.referentialGetSousStatutsByCategorie({ idCategorie: idCatStatutSocial, currentIdstatutSocial: idstatutSocial }),
    true,
    [idCatStatutSocial, idstatutSocial]
  );
  const [typesSortie, tLoading] = useReferential(a => a.referentialGetTypesSortie({}));
  const [idtypeSortie, setIdtypeSortie] = React.useState(0);
  const [motifsSortie, msLoading] = useReferential(a => a.referentialGetMotifsSortie({ idtypeSortie }), true, [
    idtypeSortie
  ]);

  const fetchParcours = React.useCallback(async () => {
    if (idParcours > 0) {
      return api.parcoursFormationGetParcoursFormation({ idApprenant: +id, idParcoursFormation: idParcours });
    } else return api.parcoursFormationGetParcoursFormation({ idApprenant: +id });
  }, [api, id, idParcours]);

  const { data, isFetching: loading, refetch } = useQuery(
    ["parcoursFormation", id, idParcoursFormation],
    fetchParcours
  );
  const refresh = React.useCallback(() => {
    dispatchEvent("SEARCH_TABLE_REFRESH");
    dispatchEvent("REFRESH_APPRENANT_SORTIR");
    refetch();
  }, [dispatchEvent, refetch]);

  const fetchCanAddParcours = React.useCallback(async () => {
    if (idParcours > 0) {
      return api.parcoursFormationCanAddParcours({ idApprenant: +id, idParcoursFormation: idParcours });
    } else return api.parcoursFormationCanAddParcours({ idApprenant: +id });
  }, [api, id, idParcours]);

  const { data: CanAddParcours, isFetching: capLoading } = useQuery(
    ["canAddParcoursFormation", id, data?.idparcoursFormation],
    fetchCanAddParcours
  );

  const AddDemandeFunc = React.useCallback(
    () =>
      history.push(
        `${ERoutes.apprenant}/${id}/parcoursFormation/edit/${data?.idparcoursFormation}/demandeInscription/0`
      ),
    [history, id, data]
  );

  const ButtonSortieReseau = React.useCallback(() => {
    return (
      <SortieReseauButton
        idApprenant={+id}
        idStatut={data?.idSousStatutEntree}
        dateStatut={data?.dateSortie}
        idCategorieStatut={data?.idStatutSocialEntree}
        idParcoursFormation={data?.idparcoursFormation}
        onClose={() => refresh()}
        loading={loading}
        isSortieEnCours={data?.isSortieEnCours}
        iddureeInoccupation={data?.iddureeInoccupation}
      />
    );
  }, [
    data?.dateSortie,
    data?.idSousStatutEntree,
    data?.idStatutSocialEntree,
    data?.iddureeInoccupation,
    data?.idparcoursFormation,
    data?.isSortieEnCours,
    id,
    loading,
    refresh
  ]);

  const ButtonAddParcours = React.useCallback(() => {
    return (
      <>
        {CanAddParcours?.value && !data?.isSortieEnCours && idParcours === 0 ? (
          <AddParcoursFormationButton
            idApprenant={+id}
            idCategorieStatut={data?.idStatutSocialEntree}
            idStatut={data?.idSousStatutEntree}
            dateEntree={data?.dateEntreeReseau ?? new Date()}
            minDateEntree={data?.lastDateSortie}
            onClose={toRefresh => {
              if (toRefresh) refresh();
            }}
            loading={loading || capLoading}
            title={t(ETLCodes.NouveauParcoursFormation)}
            iddureeInoccupation={data?.iddureeInoccupation}
          />
        ) : (
          <></>
        )}
      </>
    );
  }, [
    CanAddParcours?.value,
    capLoading,
    data?.dateEntreeReseau,
    data?.idSousStatutEntree,
    data?.idStatutSocialEntree,
    data?.iddureeInoccupation,
    data?.isSortieEnCours,
    data?.lastDateSortie,
    id,
    idParcours,
    loading,
    refresh,
    t
  ]);

  const [selectedDemandeIds, setSelectedDemandeIds] = React.useState([]);

  const [actionGlobaleLoading, setActionGlobaleLoading] = React.useState(false);
  const ChangeStatut = React.useCallback(
    async (codeStatut: string) => {
      setActionGlobaleLoading(true);
      await api.parcoursFormationChangeStatutMultipleDemande({
        ChangeStatutMultipleDto: {
          codeStatut,
          demandes: selectedDemandeIds
        }
      });
      setActionGlobaleLoading(false);
      setSelectedDemandeIds([]);
      refresh();
    },
    [api, refresh, selectedDemandeIds]
  );

  const [canSendCentre, setCanSendCentre] = React.useState(false);
  const demandeButtons = React.useMemo(
    () =>
      idParcours === 0 && !data?.idstatutSortie && !data?.isSortieEnCours ? (
        <>
          <Popover
            position="bottom"
            content={
              <PopoverContent>
                <Menu>
                  <MenuItem
                    icon={IconNames.TickCircle}
                    text={t(ETLCodes.DemanderLaValidation)}
                    intent={Intent.PRIMARY}
                    onClick={() => ChangeStatut(EStatutDemande.EV)}
                    disabled={selectedDemandeIds.length === 0}
                  />
                  <MenuItem
                    icon={IconNames.ENVELOPE}
                    text={t(ETLCodes.EnvoyerAuxCentres)}
                    intent={Intent.PRIMARY}
                    onClick={() => ChangeStatut(EStatutDemande.E)}
                    disabled={selectedDemandeIds.length === 0 || !canSendCentre}
                  />
                </Menu>
              </PopoverContent>
            }
          >
            <Button
              icon={IconNames.CHEVRON_DOWN}
              text={t(ETLCodes.GlobalActions)}
              minimal={true}
              intent={Intent.PRIMARY}
              loading={actionGlobaleLoading}
            ></Button>
          </Popover>
          {!!data?.idparcoursFormation && !data?.idstatutSortie && (
            <Button
              style={{ marginLeft: "0.5rem" }}
              intent={Intent.PRIMARY}
              text={t(ETLCodes.AddDemandeInscription)}
              onClick={() => {
                AddDemandeFunc();
              }}
              disabled={loading}
              loading={capLoading}
            />
          )}
        </>
      ) : (
        <></>
      ),
    [
      AddDemandeFunc,
      ChangeStatut,
      actionGlobaleLoading,
      canSendCentre,
      capLoading,
      data?.idparcoursFormation,
      data?.idstatutSortie,
      data?.isSortieEnCours,
      idParcours,
      loading,
      selectedDemandeIds.length,
      t
    ]
  );

  const dataTitle = React.useMemo(
    () =>
      idParcours > 0 ? t(ETLCodes.Detail) : data?.idstatutSortie ? t(ETLCodes.DernierParcours) : t(ETLCodes.EnCours),
    [data, idParcours, t]
  );

  return (
    <>
      {!!data?.idparcoursFormation && (
        <>
          <div style={{ marginBottom: "0.5rem" }}>
            <FieldSet title={dataTitle}>
              <SmallFormGenerator
                initialValues={data}
                onSubmit={() => {}}
                loading={loading}
                formatDate="dd-MM-yyyy"
                minLabelWidth={200}
                hideButtons
                watchChanges={{
                  idStatut: setIdstatutSocial,
                  idtypeSortie: setIdtypeSortie
                }}
              >
                <FGListen
                  field={"idStatutSocialEntree"}
                  onChanged={(value: any) => {
                    setIdCatStatutSocial(value);
                  }}
                />
                <FieldGroup columns={2}>
                  <FieldGroup>
                    <FGWalterDateMaskInput name="dateEntreeReseau" label={t(ETLCodes.DateEntreeReseau)} readonly />
                    <FGWalterDateMaskInput name="dateEntree" label={t(ETLCodes.DateEntreeParcours)} readonly />
                    <FGWalterSelectInput
                      name="idStatutSocialEntree"
                      label={t(ETLCodes.CategorieStatut)}
                      items={categoriesStatutSocial}
                      loading={cssLoading}
                      readonly
                    />
                    <FGWalterSelectInput
                      name="idSousStatutEntree"
                      label={t(ETLCodes.Statut)}
                      items={statutsSocial}
                      loading={ssLoading}
                      readonly
                    />
                  </FieldGroup>
                  <FieldGroup>
                    <FGCustomPanel>
                      {ctx =>
                        !!ctx.formik.values?.idparcoursFormation && !ctx.formik.values?.idstatutSortie ? (
                          ButtonSortieReseau()
                        ) : (
                          <>
                            <FGWalterDateMaskInput name="dateSortie" label={t(ETLCodes.DateSortieReseau)} readonly />
                            <FGWalterSelectInput
                              name="idStatutSocialSortie"
                              label={t(ETLCodes.Statut)}
                              items={categoriesStatutSocial}
                              loading={cssLoading}
                              readonly
                            />
                            <FGWalterSelectInput
                              name="idSousStatutSortie"
                              label={t(ETLCodes.SousStatut)}
                              items={statutsSocial}
                              loading={ssLoading}
                              readonly
                            />
                            <FGWalterSelectInput
                              name="idtypeSortie"
                              label={t(ETLCodes.TypeSortie)}
                              items={typesSortie}
                              loading={tLoading}
                              readonly
                            />
                            <FGWalterSelectInput
                              name="idMotifSortie"
                              label={t(ETLCodes.MotifSortie)}
                              items={motifsSortie}
                              loading={msLoading}
                              readonly
                            />
                          </>
                        )
                      }
                    </FGCustomPanel>
                  </FieldGroup>
                </FieldGroup>
              </SmallFormGenerator>
            </FieldSet>
          </div>
          <div style={{ marginBottom: "0.5rem" }}>
            <FieldSet title={t(ETLCodes.ParcoursInterne)} rightElement={demandeButtons}>
              <ParcoursInterneList
                idParcoursFormation={data?.idparcoursFormation}
                hasButtons={!data?.idstatutSortie}
                isSortieEnCours={data?.isSortieEnCours}
                refetchFunc={refresh}
                setSelectedDemandeIds={setSelectedDemandeIds}
                setCanSendCentre={setCanSendCentre}
              />
            </FieldSet>
          </div>
        </>
      )}
      <div style={{ marginBottom: "0.5rem" }}>
        {!idParcoursFormation ? (
          <FieldSet title={t(ETLCodes.Historique)} rightElement={ButtonAddParcours()}>
            <ParcoursFormationHistorique />
          </FieldSet>
        ) : !data?.idparcoursFormation ? (
          <ButtonContainer>{ButtonAddParcours()}</ButtonContainer>
        ) : (
          <Button
            text={t(ETLCodes.Retour)}
            intent={Intent.NONE}
            onClick={() => history.push(`${ERoutes.apprenant}/${id}/parcoursFormation/edit`)}
          />
        )}
      </div>
    </>
  );
};
