import { Classes, Icon, Radio, RadioGroup, Switch } from "@blueprintjs/core";
import { Tooltip2 } from "@blueprintjs/popover2";
import { format, isAfter, startOfDay } from "date-fns";
import { ButtonContainer, IDataTableColumn, ToolTipButton } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";

import { DashboardApi, DashboardSuiviSearch, EIfapmeSide, EOrigineSuivi, SuiviGridDto } from "../../../api";
import { ERoutes } from "../../../AppRouter";
import { CustomBulletList, LinkButton, SearchTablePage, ViewButton } from "../../../components";
import { useAuth, useDialog, useEventsContext } from "../../../contexts";
import { useAbortableApiServiceFactory, useApiService, useTabMessage, useTheme, useTl } from "../../../hooks";
import { ETLCodes } from "../../../locales";
import { Guid } from "../../../utils/guid";

const LegendHeaderContainer = styled.div`
  & > * + * {
    margin-left: 0.5rem;
  }
`;

const StyledSwitch = styled(Switch)`
  & .${Classes.SWITCH} {
    &:hover {
      input:checked ~ .${Classes.CONTROL_INDICATOR} {
        background: ${props => props.color};
      }
    }
  }
`;

export interface IDashboardSuivisProps {}

export const DashboardSuivis: React.FunctionComponent<IDashboardSuivisProps> = props => {
  const { t } = useTl();
  const { user } = useAuth();
  const { ifapmeSide, theme } = useTheme();
  const api = useApiService(DashboardApi);
  const apiFactory = useAbortableApiServiceFactory(DashboardApi);
  const lastAbortController = React.useRef<AbortController>();

  const [myDashboard, setMyDashboard] = React.useState(true);
  const [displayHidden, setDisplayHidden] = React.useState(false);
  const searchFunction = React.useCallback(
    (sObj?: DashboardSuiviSearch) => {
      sObj.ifapmeSide = ifapmeSide === "walter" ? EIfapmeSide.Walter : EIfapmeSide.Hope;
      sObj.hideMasquees = !displayHidden;
      sObj.onlyUserDashboard = myDashboard;
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.dashboardSearchSuivis({ DashboardSuiviSearch: sObj });
    },
    [apiFactory, displayHidden, ifapmeSide, myDashboard]
  );

  const [rowLoading, setRowLoading] = React.useState(null);

  const { showDialogPromise } = useDialog();
  const { dispatchEvent } = useEventsContext();
  const marquerCommeFait = React.useCallback(
    async (idsuivi: number, origin: EOrigineSuivi) => {
      const res = await showDialogPromise({
        title: t(ETLCodes.MarquerSuiviCommeFaitConfirmTitle),
        message: t(ETLCodes.MarquerSuiviCommeFaitConfirmMessage)
      });
      if (res === "yes") {
        setRowLoading({ idsuivi, origin });
        await api.dashboardMarquerSuiviFait({
          idsuivi
        });
        setRowLoading(null);
        dispatchEvent("SEARCH_TABLE_REFRESH");
        dispatchEvent("DASHBOARD_COUNTS_REFRESH_Suivis");
      }
    },
    [api, dispatchEvent, showDialogPromise, t]
  );
  const masquerSuivi = React.useCallback(
    async (idsuivi: number, origin: EOrigineSuivi) => {
      const res = await showDialogPromise({
        title: t(ETLCodes.MasquerSuiviConfirmTitle),
        message: t(ETLCodes.MasquerSuiviConfirmMessage)
      });
      if (res === "yes") {
        setRowLoading({ idsuivi, origin });
        await api.dashboardMasquerSuivi({
          idsuivi
        });
        setRowLoading(null);
        dispatchEvent("SEARCH_TABLE_REFRESH");
        dispatchEvent("DASHBOARD_COUNTS_REFRESH_Suivis");
      }
    },
    [api, dispatchEvent, showDialogPromise, t]
  );

  const getActionValue = React.useCallback((row: SuiviGridDto) => {
    if (!row.actionDateRealisation && !row.actionDateEcheance) return null;
    return format(!!row.actionDateRealisation ? row.actionDateRealisation : row.actionDateEcheance, "dd-MM-yyyy");
  }, []);

  const identifier = React.useMemo(() => Guid.newGuid(), []);
  const { openTabThenSendMessage } = useTabMessage(identifier);

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: SuiviGridDto) => (
          <ButtonContainer>
            {user.iduser === row.realisateurAction && (
              <ToolTipButton
                tooltipPosition="bottom"
                minimal
                icon="tick"
                onClick={() => marquerCommeFait(row.idsuivi, row.origineSuivi)}
                loading={!!rowLoading && rowLoading.idsuivi === row.idsuivi && rowLoading.origin === row.origineSuivi}
                disabled={!!rowLoading && rowLoading !== row.idsuivi}
                tooltip={t(ETLCodes.MarquerCommeFait)}
              />
            )}
            {!row.masquee && !!row.actionDateEcheance && isAfter(new Date(), row.actionDateEcheance) && (
              <ToolTipButton
                tooltipPosition="bottom"
                tooltip={t(ETLCodes.MasquerSuivi)}
                minimal
                icon="cross"
                onClick={() => masquerSuivi(row.idsuivi, row.origineSuivi)}
                loading={!!rowLoading && rowLoading.idsuivi === row.idsuivi && rowLoading.origin === row.origineSuivi}
                disabled={!!rowLoading && rowLoading !== row.idsuivi}
              />
            )}
            <ViewButton
              minimal={true}
              onClick={() =>
                openTabThenSendMessage(
                  row.fromHope
                    ? `#${ERoutes.suivi}/${row.idsuivi}/detail/view/hope`
                    : `#${ERoutes.apprenant}/${row.idapprenant}/suiviApprenant/${row.idsuivi}/view/${
                        row.origineSuivi === EOrigineSuivi.Centres ? "walter" : "hope"
                      }`,
                  "FromDashboard"
                )
              }
            />
          </ButtonContainer>
        )
      },
      {
        header: () => (
          <LegendHeaderContainer>
            <span>{t(ETLCodes.ActionARealiser)}</span>
            <Tooltip2
              content={
                <CustomBulletList
                  title={t(ETLCodes.Legende)}
                  items={[
                    {
                      text: t(ETLCodes.ActionRealisee),
                      color: theme.sucessColor
                    },
                    {
                      text: t(ETLCodes.DateEcheanceDepassee),
                      color: theme.dangerColor
                    },
                    {
                      text: t(ETLCodes.ActionNonRealisee),
                      color: theme.warningColor
                    }
                  ]}
                />
              }
              popoverClassName="bullet-list-popover"
              position="right"
            >
              <Icon icon="info-sign" style={{ cursor: "pointer" }} />
            </Tooltip2>
          </LegendHeaderContainer>
        ),
        fieldName: "actionARealiser",
        render: (row: SuiviGridDto) => (
          <span
            style={{
              display: "flex",
              alignItems: "center",
              color: !!row.actionDateRealisation
                ? theme.sucessColor
                : isAfter(startOfDay(new Date()), startOfDay(row.actionDateEcheance))
                ? theme.dangerColor
                : theme.warningColor
            }}
          >
            <Icon
              style={{ marginRight: ".5rem" }}
              icon={
                !!row.actionDateRealisation
                  ? "tick"
                  : isAfter(startOfDay(new Date()), startOfDay(row.actionDateEcheance))
                  ? "issue"
                  : "time"
              }
            />
            {getActionValue(row)}
          </span>
        )
      },
      {
        header: () => t(ETLCodes.Source),
        fieldName: "source",
        render: (row: SuiviGridDto) =>
          row.origineSuivi === EOrigineSuivi.Services
            ? row.service
            : row.origineSuivi === EOrigineSuivi.Centres
            ? row.centre
            : t(ETLCodes.Smile)
      },
      {
        header: () => t(ETLCodes.DateSuivi),
        fieldName: "dateSuivi"
      },
      {
        header: () => t(ETLCodes.RealisateurSuivi),
        fieldName: "realisateurSuivi",
        render: (row: SuiviGridDto) => (
          <LinkButton
            text={row.realisateurSuivi}
            onClick={() => window.open(`#${ERoutes.users}/${row.idrealisateurSuivi}/detail/view`, "_blank")}
          />
        )
      },
      {
        header: () => t(ETLCodes.Encodeur),
        fieldName: "encodeur",
        render: (row: SuiviGridDto) => (
          <LinkButton
            text={row.encodeur}
            onClick={() => window.open(`#${ERoutes.users}/${row.idencodeur}/detail/view`, "_blank")}
          />
        )
      },
      {
        header: () => t(ETLCodes.Apprenant),
        fieldName: "apprenant",
        render: (row: SuiviGridDto) => (
          <LinkButton
            text={row.apprenant}
            onClick={() => window.open(`#${ERoutes.apprenant}/${row.idapprenant}/signaletique/view`, "_blank")}
          />
        )
      },
      {
        header: () => t(ETLCodes.TypeSuivi),
        fieldName: "typeSuivi"
      },
      {
        header: () => t(ETLCodes.SousTypeSuivi),
        fieldName: "sousTypeSuivi"
      }
    ],
    [
      getActionValue,
      marquerCommeFait,
      masquerSuivi,
      openTabThenSendMessage,
      rowLoading,
      t,
      theme.dangerColor,
      theme.sucessColor,
      theme.warningColor,
      user.iduser
    ]
  );

  const getCriteria = React.useCallback(async () => {
    return await api.dashboardGetSuivisCriterias();
  }, [api]);

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <>
      <RadioGroup
        selectedValue={myDashboard ? "1" : "0"}
        inline
        onChange={e => setMyDashboard(e.currentTarget.value === "1")}
      >
        <Radio label={t(ETLCodes.ActionsQuiMeSontAssignees)} value={"1"}></Radio>
        <Radio
          label={t(ifapmeSide === "hope" ? ETLCodes.ActionsAssigneesServices : ETLCodes.ActionsAssigneesCentres)}
          value={"0"}
        ></Radio>
      </RadioGroup>
      <SearchTablePage
        searchStateInitialSearch={false}
        withCard={false}
        columns={columns}
        getCriteriasFunction={getCriteria}
        searchFunction={searchFunction}
        onAbort={onAbort}
        sortKeys={{ dateSuivi: "DESC" }}
        rightElement={
          <StyledSwitch
            color={theme.primaryColor}
            label={t(ETLCodes.AfficherMasquees)}
            checked={displayHidden}
            onChange={e => {
              setDisplayHidden(e.currentTarget.checked);
            }}
          ></StyledSwitch>
        }
      />
    </>
  );
};
