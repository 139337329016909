import { Intent } from "@blueprintjs/core";
import { ButtonContainer, DataTable, FieldSet, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import { AcquisAdmissionApi, AdmissionGridDto, AdmissionSearchDto } from "../../../../../../api";
import { AddButton, EditButton, ViewButton } from "../../../../../../components";
import { useApiService, useTl } from "../../../../../../hooks";
import { ETLCodes } from "../../../../../../locales";

export interface IAcquisAdmissionListProps {
  idpersonne: number;
  listRoute: string;
}

export const AcquisAdmissionList: React.FunctionComponent<IAcquisAdmissionListProps> = ({ idpersonne, listRoute }) => {
  const { t, tUnsafe } = useTl();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const api = useApiService(AcquisAdmissionApi);

  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: false,
    availablePageSizes: [5],
    pageSize: 5
  });

  const searchFunc = React.useCallback(
    (sObj?: AdmissionSearchDto) => {
      sObj.idpersonne = idpersonne;
      return api.acquisAdmissionBaseSearch({ AdmissionSearchDto: sObj });
    },
    [api, idpersonne]
  );

  const { loading } = useSearchApi<any, any>({
    searchFunction: searchFunc,
    tableState,
    initialSearch: true
  });

  const getTypeAcquisTab = React.useCallback((type: string) => {
    switch (type) {
      case "ExamenEntree":
        return "examenEntree";
      case "ExamenEntreeSpecifique":
        return "examenEntreeSpecifique";
      case "ExamenEntreeFCE":
        return "examenEntreeFce";
      case "DossierAdmission":
        return "dossierAdmission";
      case "BilanCompetence":
        return "bilanCompetence";
    }
  }, []);

  const columns = React.useMemo(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: AdmissionGridDto) => (
          <ButtonContainer>
            <ViewButton
              minimal={true}
              onClick={() => history.push(`${listRoute}/${getTypeAcquisTab(row.type)}/${row.id}/view`)}
            />
            <EditButton
              minimal={true}
              onClick={() => history.push(`${listRoute}/${getTypeAcquisTab(row.type)}/${row.id}/edit`)}
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Type),
        fieldName: "typeTexte",
        render: (row: AdmissionGridDto) => tUnsafe(row.type)
      },
      {
        header: () => t(ETLCodes.AnneeScolaire),
        fieldName: "anneeScolaire"
      },
      {
        header: () => t(ETLCodes.Resultat),
        fieldName: "resultat"
      }
    ],
    [history, listRoute, getTypeAcquisTab, t, tUnsafe]
  );

  const onAddItem = React.useCallback(() => {
    history.push(`${listRoute}/acquisAdmission/0/edit`);
  }, [history, listRoute]);

  return (
    <>
      {+id > 0 && (
        <FieldSet
          title={`${t(ETLCodes.ExamenEntree)} / ${t(ETLCodes.ExamenEntreeSpecifique)} / ${t(
            ETLCodes.ExamenEntreeFCE
          )} / ${t(ETLCodes.BilanCompetence)} / ${t(ETLCodes.DossierAdmission)}`}
          rightElement={
            <>
              <AddButton
                onClick={e => {
                  e.stopPropagation();
                  onAddItem();
                }}
                text={t(ETLCodes.New)}
                intent={Intent.PRIMARY}
              />
            </>
          }
        >
          <DataTable dateFormat="dd-MM-yyyy" tableState={tableState} loading={loading} columns={columns} />
        </FieldSet>
      )}
    </>
  );
};
