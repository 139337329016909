import {
  Button,
  Checkbox,
  Colors,
  Icon,
  Intent,
  Menu,
  MenuItem,
  NonIdealState,
  Popover,
  Spinner,
  Tooltip
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { faPeopleArrows } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSessionStorageState } from "ahooks";
import { IDataTableColumn, showError } from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";
import { useHistory, useLocation } from "react-router";
import styled from "styled-components";

import { AriReassignmentDialog, CopReassignmentDialog, PriReassignmentDialog, DownloadAvisValidesDialog } from "..";
import {
  ApprenantApi,
  ApprenantHopeSearch,
  ApprenantSearch,
  EHopeApprenantSearchType,
  EListSearchType,
  ETextSearchType,
  ETypeDestinataireSms,
  FcbRechercheApprenantViewDto,
  RapportHopeApi
} from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import {
  AddButton,
  ApprenantTooltip,
  CircleColumn,
  ColumnMask,
  CustomBulletList,
  EditButton,
  EmailButton,
  SearchTablePage,
  SmsButton,
  ViewButton
} from "../../../../components";
import { useAuth, useGlobalData, useRouteParamsContext } from "../../../../contexts";
import { useTabMessage, useTheme, useTl } from "../../../../hooks";
import { useAbortableApiServiceFactory, useApiService } from "../../../../hooks/useApiService";
import { ETLCodes } from "../../../../locales";
import { computeContratStatutColor } from "../../../../utils/contratStatutColor";
import { Guid } from "../../../../utils/guid";

interface IApprenantListPageProps {}

const Container = styled.div`
  display: flex;
`;

const PopoverContent = styled.div`
  padding: 0.5rem;
`;

const LegendHeaderContainer = styled.div`
  & > * + * {
    margin-left: 0.5rem;
  }
`;

export const ApprenantListPage: React.FunctionComponent<IApprenantListPageProps> = () => {
  const { t, tUnsafe } = useTl();
  const history = useHistory();
  const apiFactory = useAbortableApiServiceFactory(ApprenantApi);
  const lastAbortController = React.useRef<AbortController>();
  const { currentAnneeScolaire } = useGlobalData();
  const [selectedIDs, setSelectedIDs] = React.useState<number[]>([]);
  const { pushSmsRecipientIds, pushSmsRecipientType, pushSelectedApprenantIds } = useRouteParamsContext();
  const [selectAllLoading, setSelectAllLoading] = React.useState(false);
  const [nbReturnedIds, setNbReturnedIds] = React.useState(0);
  const { ifapmeSide, theme } = useTheme();
  const isHope = React.useMemo(() => ifapmeSide === "hope", [ifapmeSide]);
  const { user } = useAuth();
  const identifier = React.useMemo(() => Guid.newGuid(), []);
  const { openTabThenSendMessage } = useTabMessage(identifier);

  const lastSearchObject = React.useRef<ApprenantSearch | ApprenantHopeSearch>();
  const [searched, setSearched] = React.useState(false);

  const search = React.useCallback(
    (nextSearch?: ApprenantSearch | ApprenantHopeSearch) => {
      setSearched(true);
      if (JSON.stringify(nextSearch) !== JSON.stringify(lastSearchObject.current)) {
        lastSearchObject.current = nextSearch;
      }
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return isHope
        ? abortableApi.apprenantSearchHope({ ApprenantHopeSearch: nextSearch })
        : abortableApi.apprenantBaseSearch({ ApprenantSearch: nextSearch });
    },
    [apiFactory, isHope]
  );

  const api = useApiService(ApprenantApi);
  const selectAll = React.useCallback(() => {
    setSelectAllLoading(true);
    (isHope
      ? api.apprenantSearchHopeApprenantIds({
          ApprenantHopeSearch: { ...lastSearchObject.current, forceSkip: 0, forceTake: 999999 }
        })
      : api.apprenantSearchApprenantIds({
          ApprenantSearch: { ...lastSearchObject.current, forceSkip: 0, forceTake: 999999 }
        })
    ).then(allIds => {
      if (allIds.length === selectedIDs.length) {
        setSelectedIDs([]);
        setNbReturnedIds(0);
      } else {
        setSelectedIDs(allIds);
        setNbReturnedIds(allIds.length);
      }
      setSelectAllLoading(false);
    });
  }, [api, isHope, selectedIDs.length]);

  const toggleSelection = React.useCallback(
    (item: FcbRechercheApprenantViewDto) => {
      if (!selectedIDs.includes(item.idapprenant)) {
        setSelectedIDs(ids => [...ids, item.idapprenant]);
      } else {
        setSelectedIDs(ids => ids.filter(id => id !== item.idapprenant));
      }
    },
    [selectedIDs]
  );

  const sendSms = React.useCallback(() => {
    if (selectedIDs.length > 0) {
      pushSmsRecipientIds(selectedIDs);
      pushSmsRecipientType(ETypeDestinataireSms.Etudiant);
      history.push(`${ERoutes.sms}/0/detail/edit`);
    } else {
      showError(t(ETLCodes.SelectAtLeastOne));
    }
  }, [history, pushSmsRecipientIds, pushSmsRecipientType, selectedIDs, t]);

  const sendSmsSingle = React.useCallback(
    (id: number) => {
      pushSmsRecipientIds([id]);
      pushSmsRecipientType(ETypeDestinataireSms.Etudiant);
      history.push(`${ERoutes.sms}/0/detail/edit`);
    },
    [history, pushSmsRecipientIds, pushSmsRecipientType]
  );

  const mailSent = React.useCallback(
    async (idApprenant: number) => {
      await api.apprenantMailSent({ IdApprenant: idApprenant });
    },
    [api]
  );

  const createSuivi = React.useCallback(
    (idapprenant: number, mode: "walter" | "hope") =>
      history.push(`${ERoutes.apprenant}/${idapprenant}/suiviApprenant/0/edit/${mode}`),
    [history]
  );
  const createMultipleSuivi = React.useCallback(
    (mode: "walter" | "hope") => {
      if (selectedIDs.length <= 0) {
        showError(t(ETLCodes.SelectAtLeastOne));
        return;
      }
      pushSelectedApprenantIds(selectedIDs);
      history.push(`${ERoutes.suivi}/-1/multiple/edit/${mode}`);
    },
    [history, pushSelectedApprenantIds, selectedIDs, t]
  );

  const walterColumns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "checkboxes",
        autoFitContent: true,
        header: () =>
          selectAllLoading ? (
            <Spinner size={20} />
          ) : (
            <Checkbox checked={nbReturnedIds > 0 && nbReturnedIds === selectedIDs.length} onChange={selectAll} />
          ),
        render: (item: FcbRechercheApprenantViewDto) => (
          <Checkbox
            checked={selectedIDs.includes(item.idapprenant)}
            onChange={e => {
              toggleSelection(item);
            }}
          />
        )
      },
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbRechercheApprenantViewDto) => (
          <Container>
            <ViewButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.apprenant}/${row.idapprenant}/signaletique/view`)}
            />
            <EditButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.apprenant}/${row.idapprenant}/signaletique/edit`)}
            />
            <Popover
              position="bottom"
              content={
                <PopoverContent>
                  <Menu>
                    <MenuItem
                      icon={IconNames.PRINT}
                      text={t(ETLCodes.ImprimerInscription)}
                      intent={Intent.PRIMARY}
                      onClick={() => {}}
                      disabled
                    />
                    <MenuItem
                      icon={IconNames.PRINT}
                      text={t(ETLCodes.ImprimerFicheHoraire)}
                      intent={Intent.PRIMARY}
                      onClick={() => {}}
                      disabled
                    />
                    <MenuItem
                      icon={IconNames.ALIGN_JUSTIFY}
                      text={t(ETLCodes.CreateSuivi)}
                      intent={Intent.PRIMARY}
                      onClick={() => createSuivi(row.idapprenant, "walter")}
                    />
                    <MenuItem
                      icon={IconNames.ENVELOPE}
                      text={t(ETLCodes.SendAnEmail)}
                      intent={Intent.PRIMARY}
                      onClick={() => {
                        const el = document.createElement("a");
                        el.setAttribute("href", `mailto:${row.azureEmail}?bcc=${row.email}`);
                        el.click();
                        mailSent(row.idapprenant);
                      }}
                    />
                    <MenuItem
                      icon={IconNames.CHAT}
                      text={t(ETLCodes.SendAnSMS)}
                      intent={Intent.PRIMARY}
                      onClick={() => sendSmsSingle(row.idapprenant)}
                    />
                    <MenuItem
                      icon={IconNames.HEADSET}
                      text={t(ETLCodes.Call)}
                      intent={Intent.PRIMARY}
                      onClick={() => {
                        const el = document.createElement("a");
                        el.setAttribute("href", `tel:${row.gsm.replace(/[^+\d]/gm, "")}`);
                        el.click();
                        el.remove();
                        mailSent(row.idapprenant);
                      }}
                    />
                  </Menu>
                </PopoverContent>
              }
            >
              <Button icon={IconNames.CHEVRON_DOWN} minimal={true}></Button>
            </Popover>
          </Container>
        )
      },
      {
        autoFitContent: true,
        alignment: "center",
        header: () => (
          <LegendHeaderContainer>
            <span>{t(ETLCodes.C)}</span>
            <Tooltip
              content={
                <CustomBulletList
                  title={t(ETLCodes.StatutContrat)}
                  items={[
                    {
                      text: t(ETLCodes.EnCours),
                      color: theme.sucessColor
                    },
                    {
                      text: t(ETLCodes.Rompu),
                      color: theme.dangerColor
                    },
                    {
                      text: t(ETLCodes.Suspendu),
                      color: theme.warningColor
                    },
                    {
                      text: t(ETLCodes.JamaisDeContrat),
                      color: Colors.BLACK
                    },
                    {
                      text: t(ETLCodes.Termine),
                      color: Colors.VIOLET4
                    },
                    {
                      text: t(ETLCodes.SansSuite),
                      hideBullet: true
                    }
                  ]}
                />
              }
              popoverClassName="bullet-list-popover"
              position="right"
            >
              <Icon icon="info-sign" style={{ cursor: "pointer" }} />
            </Tooltip>
          </LegendHeaderContainer>
        ),
        fieldName: "statutContrat",
        render: (row: FcbRechercheApprenantViewDto) => (
          <CircleColumn
            color={computeContratStatutColor(row.statutContrat, theme)}
            tooltip={row.statutContrat ? tUnsafe(`StatutContrat_${row.statutContrat}`) : t(ETLCodes.JamaisDeContrat)}
          />
        )
      },
      {
        autoFitContent: true,
        alignment: "center",
        header: () => (
          <LegendHeaderContainer>
            <span>{t(ETLCodes.I)}</span>
            <Tooltip
              content={
                <CustomBulletList
                  title={t(ETLCodes.StatutInscriptions)}
                  items={[
                    {
                      text: t(ETLCodes.AuMoinsUneInscriptionsEnCours),
                      color: theme.sucessColor
                    },
                    {
                      text: t(ETLCodes.AucuneInscriptionEnCours),
                      color: theme.dangerColor
                    },
                    {
                      text: t(ETLCodes.AuMoinsUneInscriptionsEnAttente),
                      color: theme.warningColor
                    }
                  ]}
                />
              }
              popoverClassName="bullet-list-popover"
              position="right"
            >
              <Icon icon="info-sign" style={{ cursor: "pointer" }} />
            </Tooltip>
          </LegendHeaderContainer>
        ),
        fieldName: "statutInscriptions",
        render: (row: FcbRechercheApprenantViewDto) => (
          <CircleColumn
            color={row.encours ? theme.sucessColor : row.enattente ? theme.warningColor : theme.dangerColor}
          />
        )
      },
      {
        header: () => t(ETLCodes.Identifiant),
        computed: true,
        fieldName: "info",
        render: (value: FcbRechercheApprenantViewDto) => <ApprenantTooltip idApprenant={value.idapprenant} />
      },
      {
        header: () => t(ETLCodes.Nom),
        fieldName: "nom"
      },
      {
        header: () => t(ETLCodes.Prenom),
        fieldName: "prenom"
      },
      {
        header: () => t(ETLCodes.DateNaissance),
        fieldName: "dateNaissance"
      },
      {
        header: () => t(ETLCodes.Gsm),
        fieldName: "gsm",
        autoFitContent: true,
        render: (value: FcbRechercheApprenantViewDto) => (
          <SmsButton phone={value.gsm} onClick={() => sendSmsSingle(value.idapprenant)} disabled={!!value.dateDeces} />
        )
      },
      {
        header: () => t(ETLCodes.Tel),
        fieldName: "telephone"
      },
      {
        header: () => t(ETLCodes.General_Email),
        fieldName: "email",
        autoFitContent: true,
        alignment: "center",
        render: (value: FcbRechercheApprenantViewDto) => (
          <EmailButton
            email={value.azureEmail}
            emailBcc={value.email}
            afterClick={() => mailSent(value.idapprenant)}
            disabled={!!value.dateDeces}
          />
        )
      },
      {
        header: () => t(ETLCodes.NumeroNational),
        fieldName: "registreNational",
        render: (row: FcbRechercheApprenantViewDto) => (
          <ColumnMask
            value={row.registreNational}
            cleaveOptions={{ delimiters: [".", ".", "-", "."], blocks: [2, 2, 2, 3, 2] }}
          />
        )
      },
      {
        header: () => t(ETLCodes.Inscription),
        autoFitContent: true,
        computed: true,
        fieldName: "distinctsInscriptions",
        render: (value: FcbRechercheApprenantViewDto) => {
          if (value.distinctsInscriptions?.length > 0) {
            return (
              <span style={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                {value.anneeScolaire}
                {value.distinctsInscriptions.length > 1 && (
                  <span>
                    &nbsp;
                    <Tooltip
                      content={
                        <div>
                          {value.distinctsInscriptions.map((annee, i) => (
                            <div key={i}>{annee}</div>
                          ))}
                        </div>
                      }
                      position="right"
                    >
                      <div style={{ cursor: "pointer" }}>...</div>
                    </Tooltip>
                  </span>
                )}
              </span>
            );
          } else {
            return <div></div>;
          }
        }
      }
    ],
    [
      createSuivi,
      history,
      mailSent,
      nbReturnedIds,
      selectAll,
      selectAllLoading,
      selectedIDs,
      sendSmsSingle,
      t,
      tUnsafe,
      theme,
      toggleSelection
    ]
  );

  const rapportApi = useApiService(RapportHopeApi);
  const fetchIdrapportFicheTutelle = React.useCallback(() => rapportApi.rapportHopeGetIdrapportFicheTutelle(), [
    rapportApi
  ]);
  const { data: idrapportFicheTutelle } = useQuery("idrapportFicheTutelle", fetchIdrapportFicheTutelle);

  const hopeColumns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "checkboxes",
        autoFitContent: true,
        header: () =>
          selectAllLoading ? (
            <Spinner size={20} />
          ) : (
            <Checkbox checked={nbReturnedIds > 0 && nbReturnedIds === selectedIDs.length} onChange={selectAll} />
          ),
        render: (item: FcbRechercheApprenantViewDto) => (
          <Checkbox
            checked={selectedIDs.includes(item.idapprenant)}
            onChange={e => {
              toggleSelection(item);
            }}
          />
        )
      },
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbRechercheApprenantViewDto) => (
          <Container>
            <ViewButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.apprenant}/${row.idapprenant}/signaletique/view`)}
            />
            <EditButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.apprenant}/${row.idapprenant}/signaletique/edit`)}
            />
            <Popover
              position="bottom"
              content={
                <PopoverContent>
                  <Menu>
                    <MenuItem
                      icon={IconNames.PRINT}
                      text={t(ETLCodes.ImprimerFicheTutelle)}
                      intent={Intent.PRIMARY}
                      onClick={() =>
                        openTabThenSendMessage(`/#${ERoutes.SSRSReports}/${idrapportFicheTutelle}`, [row.idapprenant])
                      }
                      disabled={!idrapportFicheTutelle}
                    />
                    <MenuItem
                      icon={IconNames.ALIGN_JUSTIFY}
                      text={t(ETLCodes.CreateSuivi)}
                      intent={Intent.PRIMARY}
                      onClick={() => createSuivi(row.idapprenant, "hope")}
                    />
                    <MenuItem
                      icon={IconNames.ENVELOPE}
                      text={t(ETLCodes.SendAnEmail)}
                      intent={Intent.PRIMARY}
                      disabled={!!row.dateDeces}
                      onClick={() => {
                        const el = document.createElement("a");
                        el.setAttribute("href", `mailto:${row.azureEmail}?bcc=${row.email}`);
                        el.click();
                        mailSent(row.idapprenant);
                      }}
                    />
                    <MenuItem
                      icon={IconNames.CHAT}
                      text={t(ETLCodes.SendAnSMS)}
                      intent={Intent.PRIMARY}
                      onClick={() => sendSmsSingle(row.idapprenant)}
                    />
                    <MenuItem
                      icon={IconNames.HEADSET}
                      text={t(ETLCodes.Call)}
                      intent={Intent.PRIMARY}
                      onClick={() => {
                        const el = document.createElement("a");
                        el.setAttribute("href", `tel:${row.gsm.replace(/[^+\d]/gm, "")}`);
                        el.click();
                        el.remove();
                        mailSent(row.idapprenant);
                      }}
                    />
                  </Menu>
                </PopoverContent>
              }
            >
              <Button icon={IconNames.CHEVRON_DOWN} minimal={true}></Button>
            </Popover>
          </Container>
        )
      },
      {
        disableSorting: true,
        autoFitContent: true,
        alignment: "center",
        header: () => (
          <LegendHeaderContainer>
            <span>{t(ETLCodes.C)}</span>
            <Tooltip
              content={
                <CustomBulletList
                  title={t(ETLCodes.StatutContrat)}
                  items={[
                    {
                      text: t(ETLCodes.EnCours),
                      color: theme.sucessColor
                    },
                    {
                      text: t(ETLCodes.Rompu),
                      color: theme.dangerColor
                    },
                    {
                      text: t(ETLCodes.Suspendu),
                      color: theme.warningColor
                    },
                    {
                      text: t(ETLCodes.JamaisDeContrat),
                      color: Colors.BLACK
                    },
                    {
                      text: t(ETLCodes.Termine),
                      color: Colors.VIOLET4
                    },
                    {
                      text: t(ETLCodes.SansSuite),
                      hideBullet: true
                    }
                  ]}
                />
              }
              popoverClassName="bullet-list-popover"
              position="right"
            >
              <Icon icon="info-sign" style={{ cursor: "pointer" }} />
            </Tooltip>
          </LegendHeaderContainer>
        ),
        fieldName: "statutContrat",
        render: (row: FcbRechercheApprenantViewDto) => (
          <CircleColumn
            color={computeContratStatutColor(row.statutContrat, theme)}
            tooltip={row.statutContrat ? tUnsafe(`StatutContrat_${row.statutContrat}`) : t(ETLCodes.JamaisDeContrat)}
          />
        )
      },
      {
        disableSorting: true,
        autoFitContent: true,
        alignment: "center",
        header: () => (
          <LegendHeaderContainer>
            <span>{t(ETLCodes.I)}</span>
            <Tooltip
              content={
                <CustomBulletList
                  title={t(ETLCodes.StatutInscriptions)}
                  items={[
                    {
                      text: t(ETLCodes.AuMoinsUneInscriptionsEnCours),
                      color: theme.sucessColor
                    },
                    {
                      text: t(ETLCodes.AucuneInscriptionEnCours),
                      color: theme.dangerColor
                    },
                    {
                      text: t(ETLCodes.AuMoinsUneInscriptionsEnAttente),
                      color: theme.warningColor
                    }
                  ]}
                />
              }
              popoverClassName="bullet-list-popover"
              position="right"
            >
              <Icon icon="info-sign" style={{ cursor: "pointer" }} />
            </Tooltip>
          </LegendHeaderContainer>
        ),
        fieldName: "statutInscriptions",
        render: (row: FcbRechercheApprenantViewDto) => (
          <CircleColumn
            color={row.encours ? theme.sucessColor : row.enattente ? theme.warningColor : theme.dangerColor}
          />
        )
      },
      {
        header: () => t(ETLCodes.Identifiant),
        computed: true,
        fieldName: "info",
        render: (value: FcbRechercheApprenantViewDto) => <ApprenantTooltip idApprenant={value.idapprenant} />
      },
      {
        header: () => t(ETLCodes.Nom),
        fieldName: "nom"
      },
      {
        header: () => t(ETLCodes.Prenom),
        fieldName: "prenom"
      },
      {
        header: () => t(ETLCodes.NumeroNational),
        fieldName: "registreNational",
        render: (row: FcbRechercheApprenantViewDto) => (
          <ColumnMask
            value={row.registreNational}
            cleaveOptions={{ delimiters: [".", ".", "-", "."], blocks: [2, 2, 2, 3, 2] }}
          />
        )
      },
      {
        header: () => t(ETLCodes.General_Email),
        fieldName: "email",
        autoFitContent: true,
        alignment: "center",
        render: (value: FcbRechercheApprenantViewDto) => (
          <EmailButton
            email={value.azureEmail}
            emailBcc={value.email}
            afterClick={() => mailSent(value.idapprenant)}
            disabled={!!value.dateDeces}
          />
        )
      },
      {
        header: () => t(ETLCodes.Gsm),
        fieldName: "gsm",
        autoFitContent: true,
        render: (value: FcbRechercheApprenantViewDto) => (
          <SmsButton phone={value.gsm} onClick={() => sendSmsSingle(value.idapprenant)} disabled={!!value.dateDeces} />
        )
      },
      {
        header: () => t(ETLCodes.Localite),
        fieldName: "localite"
      },
      {
        header: () => t(ETLCodes.DateNaissance),
        computed: true,
        fieldName: "dateNaissance",
        render: (value: FcbRechercheApprenantViewDto) =>
          value?.isDeceased ? (
            <>
              {value?.dateNaissance?.toLocaleDateString()} <Icon icon="symbol-cross" />
            </>
          ) : (
            value?.dateNaissance?.toLocaleDateString()
          )
      }
    ],
    [
      createSuivi,
      history,
      idrapportFicheTutelle,
      mailSent,
      nbReturnedIds,
      openTabThenSendMessage,
      selectAll,
      selectAllLoading,
      selectedIDs,
      sendSmsSingle,
      t,
      tUnsafe,
      theme,
      toggleSelection
    ]
  );

  const addItemFunction = React.useCallback(() => {
    if (user?.idsSocieteExterne?.length > 0) {
      history.push(`${ERoutes.apprenant}/0/signaletique/societeExterne`);
    } else {
      history.push(`${ERoutes.apprenant}/0/signaletique/checkDoublons`);
    }
  }, [history, user?.idsSocieteExterne?.length]);

  const getCriteria = React.useCallback(async () => {
    const criterias = await (isHope
      ? api.apprenantGetHopeSearchCriterias({ includeListsValues: true })
      : api.apprenantGetSearchCriterias({ includeListsValues: true }));
    criterias.forEach(c => {
      if (c.criteria === "MesApprenants") {
        c.listValues.forEach(v => (v.displayValue = tUnsafe("EHopeApprenantSearchType_" + v.displayValue)));
      }
      if (c.criteria === "StatutContrat") {
        c.listValues.forEach(v => (v.displayValue = tUnsafe("StatutContrat_" + v.displayValue)));
      }
      if (c.criteria === "StatutInscription") {
        c.listValues.forEach(v => (v.displayValue = tUnsafe("StatutInscription_" + v.displayValue)));
      }
    });
    return criterias;
  }, [api, isHope, tUnsafe]);

  const hasDefaultCriteriaHope = React.useMemo(
    () =>
      ["CALC", "RI", "ARI", "CALC", "Gestionnaire administratif", "Société externe"].includes(user?.hopeMainLevelName),
    [user?.hopeMainLevelName]
  );

  const isSocieteExterne = React.useMemo(() => user?.hopeMainLevelName === "Société externe", [
    user?.hopeMainLevelName
  ]);

  const defaultCriteria = React.useMemo(
    () =>
      isHope
        ? hasDefaultCriteriaHope
          ? [
              {
                criteria: "MesApprenants",
                searchMode: EListSearchType.Equals,
                value: EHopeApprenantSearchType.CURRENT,
                mandatory: isSocieteExterne,
                readOnly: isSocieteExterne
              }
            ]
          : []
        : [
            {
              criteria: "AnneeScolaireCriteria",
              searchMode: ETextSearchType.Contains,
              value: currentAnneeScolaire?.idanneeScolaire
            }
          ],
    [currentAnneeScolaire?.idanneeScolaire, hasDefaultCriteriaHope, isHope, isSocieteExterne]
  );

  const sendEmail = React.useCallback(async () => {
    if (selectedIDs.length > 0) {
      const res = await api.apprenantGetEmails({ request_body: selectedIDs });
      if (!res) {
        showError(t(ETLCodes.NoSelectedApprenantHasAnEmail));
        return;
      }
      const el = document.createElement("a");
      el.setAttribute("href", `mailto:${res.azureEmails}?bcc=${res.emails}`);
      el.click();
    } else {
      showError(t(ETLCodes.SelectAtLeastOne));
    }
  }, [api, selectedIDs, t]);

  const isCalcOrAdmin = React.useMemo(() => user?.hopeMainLevelName === "CALC" || user?.isAdmin, [
    user?.hopeMainLevelName,
    user?.isAdmin
  ]);

  const [copReassignmentDialogOpen, setCopReassignmentDialogOpen] = React.useState(false);
  const reassignCop = React.useCallback(() => {
    if (selectedIDs.length <= 0) {
      showError(t(ETLCodes.SelectAtLeastOne));
      return;
    }

    setCopReassignmentDialogOpen(true);
  }, [selectedIDs.length, t]);

  const onCloseCopReassignment = React.useCallback(() => {
    setCopReassignmentDialogOpen(false);
  }, []);

  const [priReassignmentDialogOpen, setPriReassignmentDialogOpen] = React.useState(false);
  const reassignPri = React.useCallback(async () => {
    if (selectedIDs.length <= 0) {
      showError(t(ETLCodes.SelectAtLeastOne));
      return;
    }

    const hasContratsActifs = await api.apprenantHasContratActif({ request_body: selectedIDs });
    if (hasContratsActifs.value) {
      showError(t(ETLCodes.ContratsActifsEnCoursApprenant));
      return;
    }

    setPriReassignmentDialogOpen(true);
  }, [api, selectedIDs, t]);

  const onClosePriReassignment = React.useCallback(() => {
    setPriReassignmentDialogOpen(false);
  }, []);

  const [ariReassignmentDialogOpen, setAriReassignmentDialogOpen] = React.useState(false);
  const reassignAri = React.useCallback(async () => {
    if (selectedIDs.length <= 0) {
      showError(t(ETLCodes.SelectAtLeastOne));
      return;
    }

    const hasContratsActifs = await api.apprenantHasContratActif({ request_body: selectedIDs });
    if (hasContratsActifs.value) {
      showError(t(ETLCodes.ContratsActifsEnCoursApprenant));
      return;
    }

    setAriReassignmentDialogOpen(true);
  }, [api, selectedIDs, t]);

  const onCloseAriReassignment = React.useCallback(() => {
    setAriReassignmentDialogOpen(false);
  }, []);

  const [downloadAvisValidesDialogOpen, setDownloadAvisValidesDialogOpen] = React.useState<{ is2sess: boolean }>(null);
  const downloadAvisValides = React.useCallback(
    (is2sess: boolean) => {
      if (selectedIDs.length <= 0) {
        showError(t(ETLCodes.SelectAtLeastOne));
        return;
      }

      setDownloadAvisValidesDialogOpen({ is2sess });
    },
    [selectedIDs.length, t]
  );

  const onDownloadAvisValidesDialogOpenClose = React.useCallback(() => {
    setDownloadAvisValidesDialogOpen(null);
  }, []);

  const buttons = React.useMemo(
    () => (
      <>
        <Popover
          position="bottom"
          content={
            <PopoverContent>
              <Menu>
                {isHope && (
                  <MenuItem
                    icon={IconNames.PRINT}
                    text={t(ETLCodes.PrintFicheTutelle)}
                    intent={Intent.PRIMARY}
                    onClick={() => {
                      if (selectedIDs.length <= 0) {
                        showError(t(ETLCodes.SelectAtLeastOne));
                        return;
                      }
                      openTabThenSendMessage(`/#${ERoutes.SSRSReports}/${idrapportFicheTutelle}`, selectedIDs);
                    }}
                  />
                )}
                <MenuItem
                  icon={IconNames.ALIGN_JUSTIFY}
                  text={t(ETLCodes.CreateSuivi)}
                  intent={Intent.PRIMARY}
                  onClick={() => createMultipleSuivi(isHope ? "hope" : "walter")}
                />
                {isHope && isCalcOrAdmin && (
                  <>
                    <MenuItem
                      icon={<FontAwesomeIcon icon={faPeopleArrows} />}
                      text={t(ETLCodes.ReassignerCOP)}
                      intent={Intent.PRIMARY}
                      onClick={() => reassignCop()}
                    />
                    <MenuItem
                      icon={<FontAwesomeIcon icon={faPeopleArrows} />}
                      text={t(ETLCodes.ReassignerPRI)}
                      intent={Intent.PRIMARY}
                      onClick={() => reassignPri()}
                    />
                    <MenuItem
                      icon={<FontAwesomeIcon icon={faPeopleArrows} />}
                      text={t(ETLCodes.ReassignerARI)}
                      intent={Intent.PRIMARY}
                      onClick={() => reassignAri()}
                    />
                  </>
                )}
                <MenuItem
                  icon={IconNames.ENVELOPE}
                  text={t(ETLCodes.SendAnEmail)}
                  intent={Intent.PRIMARY}
                  onClick={sendEmail}
                />
                <MenuItem
                  icon={IconNames.CHAT}
                  text={t(ETLCodes.SendAnSMS)}
                  intent={Intent.PRIMARY}
                  onClick={sendSms}
                />
                <MenuItem
                  icon={IconNames.Download}
                  text={t(ETLCodes.DownloadAvisResultats1S)}
                  intent={Intent.PRIMARY}
                  onClick={() => downloadAvisValides(false)}
                />
                <MenuItem
                  icon={IconNames.Download}
                  text={t(ETLCodes.DownloadAvisResultats2S)}
                  intent={Intent.PRIMARY}
                  onClick={() => downloadAvisValides(true)}
                />
              </Menu>
            </PopoverContent>
          }
        >
          <Button
            icon={IconNames.CHEVRON_DOWN}
            text={t(ETLCodes.GlobalActions)}
            minimal={true}
            intent={Intent.PRIMARY}
          ></Button>
        </Popover>
        <AddButton
          onClick={e => {
            e.stopPropagation();
            addItemFunction();
          }}
          text={t(ETLCodes.General_Add)}
          intent={Intent.PRIMARY}
        />
      </>
    ),
    [
      addItemFunction,
      createMultipleSuivi,
      downloadAvisValides,
      idrapportFicheTutelle,
      isCalcOrAdmin,
      isHope,
      openTabThenSendMessage,
      reassignAri,
      reassignCop,
      reassignPri,
      selectedIDs,
      sendEmail,
      sendSms,
      t
    ]
  );

  const columns = React.useMemo(() => (isHope ? hopeColumns : walterColumns), [hopeColumns, isHope, walterColumns]);

  const location = useLocation();
  const [savedCriterias] = useSessionStorageState("search_" + location.pathname, { defaultValue: [] });

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <>
      <SearchTablePage
        columns={columns}
        getCriteriasFunction={getCriteria}
        searchFunction={search}
        onAbort={onAbort}
        sortKeys={{ nom: "ASC" }}
        breadCrumbs={[{ text: t(ETLCodes.Apprenants) }]}
        defaultCriterias={defaultCriteria}
        rightElement={buttons}
        initialSearch={!isHope || hasDefaultCriteriaHope || savedCriterias?.length > 0}
        searchStateInitialSearch={false}
        renderNoData={
          <NonIdealState
            icon="search"
            title={t(searched ? ETLCodes.GeneralNoData : ETLCodes.VeuillezEffectuerUneRecherche)}
          />
        }
      />
      {copReassignmentDialogOpen && (
        <CopReassignmentDialog
          dialogOpen={copReassignmentDialogOpen}
          onClose={onCloseCopReassignment}
          idsApprenant={selectedIDs}
        />
      )}
      {priReassignmentDialogOpen && (
        <PriReassignmentDialog
          dialogOpen={priReassignmentDialogOpen}
          onClose={onClosePriReassignment}
          idsApprenant={selectedIDs}
        />
      )}
      {ariReassignmentDialogOpen && (
        <AriReassignmentDialog
          dialogOpen={ariReassignmentDialogOpen}
          onClose={onCloseAriReassignment}
          idsApprenant={selectedIDs}
        />
      )}
      {!!downloadAvisValidesDialogOpen && (
        <DownloadAvisValidesDialog
          dialogOpen={!!downloadAvisValidesDialogOpen}
          onClose={onDownloadAvisValidesDialogOpenClose}
          is2sess={downloadAvisValidesDialogOpen?.is2sess}
          idsApprenant={selectedIDs}
        />
      )}
    </>
  );
};
