import { Button, Card, Intent } from "@blueprintjs/core";
import { format } from "date-fns";
import { FieldArray, FormikProps, getIn } from "formik";
import { FGCustomInput, FGCustomPanel, FGListen, FieldGroup, FieldSet, IFGContext } from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";

import {
  CentreApi,
  DemandeInscriptionCreateDto,
  DemandeInscriptionCreateDtoFromJSON,
  EIfapmeSide,
  EStatutDemande,
  ParcoursFormationApi
} from "../../../../../../api";
import { DemandeInscriptionEditDtoFromJSON } from "../../../../../../api/models/DemandeInscriptionEditDto";
import { ERoutes } from "../../../../../../AppRouter";
import { ErrorText, FGWalterSelectInput, SmallFormGenerator, WarningText } from "../../../../../../components";
import { useApiService, useCrudApi, useTheme, useTl } from "../../../../../../hooks";
import { useReferential } from "../../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../../locales";
import { CentresDemandeSelect } from "./CentresDemandeSelect";

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
`;

const ErrorTextContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export interface IDemandeInscriptionCreateProps {}

export const DemandeInscriptionCreate: React.FunctionComponent<IDemandeInscriptionCreateProps> = () => {
  const { t } = useTl();
  const api = useApiService(ParcoursFormationApi);
  const history = useHistory();

  const { id, state, idParcoursFormation } = useParams<{
    id: string;
    state: string;
    idParcoursFormation: string;
    idDemande: string;
  }>();

  const idParcours = React.useMemo(() => (+idParcoursFormation > 0 ? +idParcoursFormation : 0), [idParcoursFormation]);
  const editMode = React.useMemo(() => state === "edit", [state]);

  const formikInnerRef = React.useRef<FormikProps<DemandeInscriptionCreateDto>>();

  const fetchParcours = React.useCallback(async () => {
    if (idParcours > 0) {
      return api.parcoursFormationGetParcoursFormation({ idApprenant: +id, idParcoursFormation: idParcours });
    } else return api.parcoursFormationGetParcoursFormation({ idApprenant: +id });
  }, [api, id, idParcours]);

  const { data: parcours } = useQuery(["parcoursFormation", id, idParcoursFormation], fetchParcours);

  const { data, loading, saveItem, saving, validationErrors } = useCrudApi({
    getApiFn: () =>
      DemandeInscriptionCreateDtoFromJSON({
        idParcoursFormation: idParcours,
        demandes: [{ idparcoursFormation: idParcours }]
      }),
    saveApiFn: d => api.parcoursFormationCreateDemandeInscriptionMultiple({ DemandeInscriptionCreateDto: d }),
    onSavedRoute: () => `${ERoutes.apprenant}/${id}/parcoursFormation/${state}`
  });

  const [categoriesStatutSocial] = useReferential(
    a => a.referentialGetCategoriesStatutSocial({ currentIdcategorieStatutSocial: 0 }),
    true
  );
  const [statutsSocial] = useReferential(a => a.referentialGetSousStatutsByCategorie({ currentIdstatutSocial: 0 }));
  const [services, sLoading] = useReferential(a => a.referentialGetServicesByIdApprenant({ idApprenant: +id }));

  const [metiers, mLoading, , rawMetiers] = useReferential(a => a.referentialGetMetier());
  const [annees, aLoading] = useReferential(a => a.referentialGetAnneeScolaire());
  const { ifapmeSide } = useTheme();
  const [typeCours, tcLoading, , rawTypesCours] = useReferential(
    a => a.referentialGetTypeCoursId({ ifapmeSide: ifapmeSide === "hope" ? EIfapmeSide.Hope : EIfapmeSide.Walter }),
    false,
    [ifapmeSide]
  );
  const [stades, stLoading, , rawStades] = useReferential(a => a.referentialGetStade());

  const [degres, dLoading, , rawDegres] = useReferential(a => a.referentialGetDegreIds());

  const categorie = React.useMemo(
    () => categoriesStatutSocial?.find(c => +c.value === parcours?.idStatutSocialEntree)?.label,
    [categoriesStatutSocial, parcours]
  );
  const statut = React.useMemo(() => statutsSocial?.find(c => +c.value === parcours?.idSousStatutEntree)?.label, [
    parcours,
    statutsSocial
  ]);

  const filteredStades = React.useCallback(
    idmetier => {
      const idstade = (rawMetiers ?? [])?.find(m => +m.idValue === +idmetier)?.keyValue;
      return (stades ?? []).filter(s => +s.value === +idstade);
    },
    [rawMetiers, stades]
  );

  const filteredDegres = React.useCallback(
    idmetier => {
      const idstade = (rawMetiers ?? [])?.find(m => +m.idValue === +idmetier)?.keyValue;
      if (+idstade === 2) {
        const filteredRawDegres = (rawDegres ?? [])
          .filter(s => ["00", "01", "02", "03"].includes(s.displayValue.substr(0, 2)))
          .map(d => +d.idValue);
        return degres?.filter(d => filteredRawDegres.includes(+d.value));
      }
      const filteredRawDegres = (rawDegres ?? []).filter(s => +s.keyValue === +idstade).map(d => +d.idValue);
      return degres?.filter(d => filteredRawDegres.includes(+d.value));
    },
    [degres, rawDegres, rawMetiers]
  );

  const onCancelFunc = React.useCallback(() => {
    history.push(`${ERoutes.apprenant}/${id}/parcoursFormation/${state}`);
  }, [history, id, state]);

  const centreApi = useApiService(CentreApi);
  const [idscentre, setIdscentre] = React.useState([]);
  const fetchCentreExterieur = React.useCallback(() => centreApi.centreIsAnyExterieur({ request_body: idscentre }), [
    centreApi,
    idscentre
  ]);
  const { data: centreExterieur, isFetching: loadingCentreExterieur } = useQuery(
    ["any-centre-exterieur", idscentre],
    fetchCentreExterieur
  );

  const sendButtons = React.useCallback(
    (ctx: IFGContext<DemandeInscriptionCreateDto>) => {
      const canSendCentres =
        ctx.formik?.values?.demandes?.length > 0 &&
        !!ctx.formik?.values?.idService &&
        ctx.formik?.values?.demandes?.every(
          d =>
            !!d.idcentre &&
            !!d.anneeAcademique &&
            !!d.iddegre &&
            !!d.idmetier &&
            !!d.idstade &&
            !!d.idtypeCours &&
            rawStades?.find(s => +s.idValue === +d.idstade)?.keyValue === "AP"
        );
      const canSendValidation =
        ctx.formik?.values?.demandes?.length > 0 &&
        !!ctx.formik?.values?.idService &&
        ctx.formik?.values?.demandes?.every(
          d => !!d.idcentre && !!d.anneeAcademique && !!d.iddegre && !!d.idmetier && !!d.idstade && !!d.idtypeCours
        );
      return centreExterieur?.value ||
        ctx.formik?.values?.demandes?.some(
          d => rawTypesCours?.find(t => +t.idValue === +d.idtypeCours)?.keyValue === "U"
        ) ? (
        <></>
      ) : (
        <>
          <Button
            style={{ marginLeft: "0.5rem" }}
            intent={Intent.PRIMARY}
            text={t(ETLCodes.EnvoyerEnValidation)}
            onClick={() => {
              if (formikInnerRef.current?.isValid)
                formikInnerRef.current?.setFieldValue("statutToSend", EStatutDemande.EV);
              formikInnerRef.current?.submitForm();
            }}
            loading={saving || loadingCentreExterieur}
            disabled={!canSendValidation}
          />
          <Button
            style={{ marginLeft: "0.5rem" }}
            intent={Intent.PRIMARY}
            text={t(ETLCodes.EnvoyerAuxCentres)}
            onClick={() => {
              if (formikInnerRef.current?.isValid)
                formikInnerRef.current?.setFieldValue("statutToSend", EStatutDemande.E);
              formikInnerRef.current?.submitForm();
            }}
            loading={saving || loadingCentreExterieur}
            disabled={!canSendCentres}
          />
        </>
      );
    },
    [centreExterieur?.value, loadingCentreExterieur, rawStades, rawTypesCours, saving, t]
  );

  const clearCentre = React.useCallback((index: number, formik: FormikProps<DemandeInscriptionCreateDto>) => {
    if (!!getIn(formik.values, `demandes.${index}.idcentre`)) {
      formik.setFieldValue(`demandes.${index}.idcentre`, null);
    }
  }, []);

  const canShowCentre = React.useCallback(
    idtypeCours => {
      return !idtypeCours || rawTypesCours?.find(t => +t.idValue === +idtypeCours)?.keyValue !== "U";
    },
    [rawTypesCours]
  );

  return (
    <FieldSet title={t(ETLCodes.DemandeInscription)}>
      <SmallFormGenerator
        initialValues={data}
        onSubmit={saveItem}
        loading={loading}
        saving={saving}
        formatDate="dd-MM-yyyy"
        minLabelWidth={200}
        editMode={editMode}
        onCancel={onCancelFunc}
        validationErrors={validationErrors}
        additionalRightButtons={sendButtons}
        formikInnerRef={formikInnerRef}
        watchChanges={{
          demandes: value => setIdscentre(value?.filter(d => !!d.idcentre)?.map(d => d.idcentre) ?? [])
        }}
      >
        <FieldGroup columns={2}>
          <FieldGroup>
            <FGCustomInput label={t(ETLCodes.DateEntree)}>
              {() => (
                <div style={{ marginTop: 6 }}>
                  {!!parcours?.dateEntree ? format(parcours?.dateEntree, "dd-MM-yyyy") : ""}
                </div>
              )}
            </FGCustomInput>
            <FGCustomInput label={t(ETLCodes.Statut)}>
              {() => <div style={{ marginTop: 6 }}>{categorie}</div>}
            </FGCustomInput>
            <FGCustomInput label={t(ETLCodes.SousStatut)}>
              {() => <div style={{ marginTop: 6 }}>{statut}</div>}
            </FGCustomInput>
          </FieldGroup>
          <FieldGroup>
            <FGWalterSelectInput
              label={t(ETLCodes.Service)}
              name="idService"
              items={services}
              loading={sLoading}
              autoSelectIfOne
              disabled={services?.length <= 1}
              readonly={services?.length === 1}
              helperText={!services?.length && <WarningText text={t(ETLCodes.AucunServicePourCetApprenant)} />}
            />
          </FieldGroup>
        </FieldGroup>
        <FieldGroup>
          <FGCustomInput>
            {({ formik, validationErrors }) => (
              <FieldArray
                name="demandes"
                render={arrayHelpers => {
                  const data = (formik.values as DemandeInscriptionCreateDto)?.demandes;
                  return (
                    <FieldGroup>
                      {data?.map((d, index) => (
                        <Card title={t(ETLCodes.Demande) + " " + (index + 1)} style={{ margin: "0 1rem 0.5rem" }}>
                          <FieldGroup columns={2}>
                            <FieldGroup>
                              <FGListen
                                field={`demandes.${index}.idmetier`}
                                onChanged={(_, formik) => clearCentre(index, formik)}
                              />
                              <FGListen
                                field={`demandes.${index}.anneeAcademique`}
                                onChanged={(_, formik) => clearCentre(index, formik)}
                              />
                              <FGListen
                                field={`demandes.${index}.idtypeCours`}
                                onChanged={(_, formik) => clearCentre(index, formik)}
                              />
                              <FGListen
                                field={`demandes.${index}.iddegre`}
                                onChanged={(_, formik) => clearCentre(index, formik)}
                              />
                              <FGWalterSelectInput
                                label={t(ETLCodes.Metier)}
                                name={`demandes.${index}.idmetier`}
                                items={metiers}
                                loading={mLoading}
                              />
                              <FGWalterSelectInput
                                label={t(ETLCodes.Annee)}
                                name={`demandes.${index}.anneeAcademique`}
                                items={annees}
                                loading={aLoading}
                              />
                              <FGWalterSelectInput
                                label={t(ETLCodes.TypeCours)}
                                name={`demandes.${index}.idtypeCours`}
                                items={typeCours}
                                loading={tcLoading}
                              />
                              <FGCustomPanel>
                                {ctx => (
                                  <>
                                    <FGWalterSelectInput
                                      label={t(ETLCodes.Degre)}
                                      name={`demandes.${index}.iddegre`}
                                      items={filteredDegres(ctx?.formik?.values?.demandes?.[index]?.idmetier)}
                                      loading={dLoading}
                                    />
                                    <FGWalterSelectInput
                                      label={t(ETLCodes.Stade)}
                                      name={`demandes.${index}.idstade`}
                                      items={filteredStades(ctx?.formik?.values?.demandes?.[index]?.idmetier)}
                                      loading={stLoading}
                                      autoSelectIfOne
                                    />
                                    {canShowCentre(ctx?.formik?.values?.demandes[index].idtypeCours) && (
                                      <CentresDemandeSelect name={`demandes.${index}.idcentre`} values={d} />
                                    )}
                                  </>
                                )}
                              </FGCustomPanel>
                            </FieldGroup>
                            <FieldGroup>
                              <Button
                                icon="cross"
                                intent="danger"
                                outlined
                                disabled={data.length <= 1}
                                title={t(ETLCodes.Remove)}
                                onClick={() => arrayHelpers.remove(index)}
                                style={{ height: "16px" }}
                              ></Button>
                            </FieldGroup>
                          </FieldGroup>
                          {validationErrors[`demandes[${index}]`]?.length > 0 && (
                            <ErrorTextContainer>
                              <ErrorText text={validationErrors[`demandes[${index}]`][0]} />
                            </ErrorTextContainer>
                          )}
                        </Card>
                      ))}
                      <FGCustomPanel>
                        {ctx => (
                          <ButtonContainer>
                            <Button
                              rightIcon="plus"
                              intent="primary"
                              style={{ fontSize: "12pt" }}
                              minimal
                              text={t(ETLCodes.AddDemandeInscription)}
                              onClick={() => {
                                arrayHelpers.insert(
                                  data?.length ?? 0,
                                  DemandeInscriptionEditDtoFromJSON({
                                    idparcoursFormation: idParcours,
                                    idmetier: ctx?.formik?.values?.demandes?.[0]?.idmetier,
                                    anneeAcademique: ctx?.formik?.values?.demandes?.[0]?.anneeAcademique,
                                    idstade: ctx?.formik?.values?.demandes?.[0]?.idstade
                                  })
                                );
                              }}
                            ></Button>
                          </ButtonContainer>
                        )}
                      </FGCustomPanel>
                    </FieldGroup>
                  );
                }}
              ></FieldArray>
            )}
          </FGCustomInput>
        </FieldGroup>
      </SmallFormGenerator>
    </FieldSet>
  );
};
