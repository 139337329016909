import { Icon, Tooltip } from "@blueprintjs/core";
import { format, isAfter, startOfDay } from "date-fns";
import { ButtonContainer, IDataTableColumn } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { ENumericSearchType, EOrigineSuivi, SuiviApi, SuiviGlobalSearchDto, SuiviGridDto } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { CustomBulletList, EditButton, LinkButton, SearchTablePage, ViewButton } from "../../../../components";
import { useAuth } from "../../../../contexts";
import { useAbortableApiServiceFactory, useApiService, useTheme, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";

const LegendHeaderContainer = styled.div`
  & > * + * {
    margin-left: 0.5rem;
  }
`;

export interface SuiviSearchPageProps {}

export const SuiviSearchPage: React.FunctionComponent<SuiviSearchPageProps> = props => {
  const { t, tUnsafe } = useTl();
  const history = useHistory();
  const api = useApiService(SuiviApi);
  const { user } = useAuth();
  const { theme } = useTheme();

  const getActionValue = React.useCallback((row: SuiviGridDto) => {
    if (!row.actionDateRealisation && !row.actionDateEcheance) return null;
    return format(!!row.actionDateRealisation ? row.actionDateRealisation : row.actionDateEcheance, "dd-MM-yyyy");
  }, []);

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: SuiviGridDto) =>
          ((row.fromHope && user.ifapmeSide.includes("Hope")) ||
            (!row.fromHope && user.ifapmeSide.includes("Walter"))) && (
            <ButtonContainer>
              <ViewButton
                minimal={true}
                onClick={() =>
                  history.push(
                    row.fromHope
                      ? `${ERoutes.suivi}/${row.idsuivi}/detail/view/hope`
                      : `${ERoutes.apprenant}/${row.idapprenant}/suiviApprenant/${row.idsuivi}/view/walter`
                  )
                }
              />

              {row.idrealisateurSuivi === user.iduser && (
                <EditButton
                  minimal={true}
                  onClick={() =>
                    history.push(
                      row.fromHope
                        ? `${ERoutes.suivi}/${row.idsuivi}/detail/edit/hope`
                        : `${ERoutes.apprenant}/${row.idapprenant}/suiviApprenant/${row.idsuivi}/edit/walter`
                    )
                  }
                />
              )}
            </ButtonContainer>
          )
      },
      {
        header: () => t(ETLCodes.Source),
        fieldName: "source",
        render: (row: SuiviGridDto) =>
          row.origineSuivi === EOrigineSuivi.Services
            ? row.service
            : row.origineSuivi === EOrigineSuivi.Centres
            ? row.centre
            : t(ETLCodes.Smile)
      },
      {
        header: () => t(ETLCodes.DateSuivi),
        fieldName: "dateSuivi"
      },
      {
        header: () => t(ETLCodes.RealisateurSuivi),
        fieldName: "realisateurSuivi",
        render: (row: SuiviGridDto) =>
          row.origineSuivi !== EOrigineSuivi.Centres ? (
            <LinkButton
              text={row.realisateurSuivi}
              onClick={() => window.open(`#${ERoutes.users}/${row.idrealisateurSuivi}/detail/view`, "_blank")}
            />
          ) : (
            row.realisateurSuivi
          )
      },
      {
        header: () => t(ETLCodes.Encodeur),
        fieldName: "encodeur",
        render: (row: SuiviGridDto) => (
          <LinkButton
            text={row.encodeur}
            onClick={() => window.open(`#${ERoutes.users}/${row.idencodeur}/detail/view`, "_blank")}
          />
        )
      },
      {
        header: () => t(ETLCodes.Apprenant),
        fieldName: "apprenant",
        render: (row: SuiviGridDto) => (
          <LinkButton
            text={row.apprenant}
            onClick={() => window.open(`#${ERoutes.apprenant}/${row.idapprenant}/signaletique/view`, "_blank")}
          />
        )
      },
      {
        header: () => t(ETLCodes.TypeSuivi),
        fieldName: "typeSuivi"
      },
      {
        header: () => t(ETLCodes.SousTypeSuivi),
        fieldName: "sousTypeSuivi"
      },
      {
        header: () => (
          <LegendHeaderContainer>
            <span>{t(ETLCodes.ActionARealiser)}</span>
            <Tooltip
              content={
                <CustomBulletList
                  title={t(ETLCodes.Legende)}
                  items={[
                    {
                      text: t(ETLCodes.ActionRealisee),
                      color: theme.sucessColor
                    },
                    {
                      text: t(ETLCodes.DateEcheanceDepassee),
                      color: theme.dangerColor
                    },
                    {
                      text: t(ETLCodes.ActionNonRealisee),
                      color: theme.warningColor
                    }
                  ]}
                />
              }
              popoverClassName="bullet-list-popover"
              position="right"
            >
              <Icon icon="info-sign" style={{ cursor: "pointer" }} />
            </Tooltip>
          </LegendHeaderContainer>
        ),
        fieldName: "actionARealiser",
        render: (row: SuiviGridDto) =>
          (!!row.actionDateRealisation || row.actionDateEcheance) && (
            <span
              style={{
                display: "flex",
                alignItems: "center",
                color: !!row.actionDateRealisation
                  ? theme.sucessColor
                  : isAfter(startOfDay(new Date()), startOfDay(row.actionDateEcheance))
                  ? theme.dangerColor
                  : theme.warningColor
              }}
            >
              <Icon
                style={{ marginRight: ".5rem" }}
                icon={
                  !!row.actionDateRealisation
                    ? "tick"
                    : isAfter(startOfDay(new Date()), startOfDay(row.actionDateEcheance))
                    ? "issue"
                    : "time"
                }
              />
              {getActionValue(row)}
            </span>
          )
      }
    ],
    [getActionValue, history, t, theme.dangerColor, theme.sucessColor, theme.warningColor, user.iduser, user.ifapmeSide]
  );

  const getCriteriasFunc = React.useCallback(async () => {
    let criterias = await api.suiviGetSearchCriterias({ includeListsValues: true });
    criterias = criterias.map(c => {
      if (c.criteria === "SuiviAction") {
        c.listValues.forEach(v => (v.displayValue = tUnsafe("SuiviAction_" + v.displayValue)));
      }
      return c;
    });
    return criterias;
  }, [api, tUnsafe]);

  const apiFactory = useAbortableApiServiceFactory(SuiviApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFunc = React.useCallback(
    (nextSearch?: SuiviGlobalSearchDto) => {
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.suiviBaseSearch({ SuiviGlobalSearchDto: nextSearch });
    },
    [apiFactory]
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <SearchTablePage
      columns={columns}
      getCriteriasFunction={getCriteriasFunc}
      searchFunction={searchFunc}
      onAbort={onAbort}
      searchStateInitialSearch={false}
      breadCrumbs={[{ text: t(ETLCodes.Suivis) }]}
      sortKeys={{ dateSuivi: "DESC", encodeur: "ASC" }}
      defaultCriterias={[
        {
          criteria: "onlyUserSuivis",
          searchMode: ENumericSearchType.Equals,
          value: true
        }
      ]}
    />
  );
};
