import * as React from "react";
import { useHistory } from "react-router";

import { ApprenantSuiviForm, SuiviApprenantForm } from "../..";
import {
  EContentHidingMode,
  SuiviApi,
  SuiviHopeMultipleEditDtoFromJSON,
  SuiviWalterMultipleEditDtoFromJSON
} from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { useAuth, useRouteParamsContext } from "../../../../contexts";
import { useApiService, useCrudApi, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";

export interface IApprenantMultipleSuiviPageProps {
  mode: "hope" | "walter";
}

export const ApprenantMultipleSuiviPage: React.FunctionComponent<IApprenantMultipleSuiviPageProps> = ({ mode }) => {
  const { t } = useTl();
  const { user } = useAuth();
  const history = useHistory();
  const api = useApiService(SuiviApi);
  const { popSelectedApprenantIds } = useRouteParamsContext();
  const [selectedIds] = React.useState(() => popSelectedApprenantIds());

  const { data, saveItem, saving, validationErrors } = useCrudApi(
    React.useMemo(
      () => ({
        getApiFn: async () => {
          return mode === "hope"
            ? SuiviHopeMultipleEditDtoFromJSON({
                idsuivi: 0,
                idsApprenant: selectedIds ?? [],
                iduserService: user.iduser,
                encodeur: user.nom + " " + user.prenom,
                dateSuivi: new Date(),
                contentHidingMode: EContentHidingMode.NONE
              })
            : SuiviWalterMultipleEditDtoFromJSON({
                idsuivi: 0,
                idsApprenant: selectedIds ?? [],
                idrealisateurSuivi: user.iduser,
                encodeur: user.nom + " " + user.prenom,
                dateSuivi: new Date(),
                contentHidingMode: EContentHidingMode.NONE
              });
        },
        saveApiFn: d =>
          mode === "hope"
            ? api.suiviSaveSuiviHopeMultiple({ SuiviHopeMultipleEditDto: d })
            : api.suiviSaveSuiviWalterMultiple({ SuiviWalterMultipleEditDto: d }),
        onSavedRoute: d => ERoutes.apprenant
      }),
      [mode, selectedIds, user.iduser, user.nom, user.prenom, api]
    )
  );

  return mode === "hope" ? (
    <SuiviApprenantForm
      data={data}
      suiviId={-1}
      saveItem={saveItem}
      saving={saving}
      editMode={true}
      onCancel={() => history.push(ERoutes.suivi)}
      validationErrors={validationErrors}
      loading={false}
      fieldsetTitle={t(ETLCodes.CreateSuiviForNbApprenants, { count: selectedIds?.length ?? 0 })}
    />
  ) : (
    <ApprenantSuiviForm
      data={data}
      suiviId={-1}
      saveItem={saveItem}
      saving={saving}
      editMode={true}
      onCancel={() => history.push(ERoutes.suivi)}
      validationErrors={validationErrors}
      loading={false}
      fieldsetTitle={t(ETLCodes.CreateSuiviForNbApprenants, { count: selectedIds?.length ?? 0 })}
      idsApprenant={selectedIds}
    />
  );
};
