import * as React from "react";
import { Tab } from "@blueprintjs/core";
import { useParams, useHistory } from "react-router";
import { ERoutes } from "../../../../AppRouter";
import { PageBase, ProtectedTabs, BackButton } from "../../../../components";
import { useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";
import { SuiviDetail } from "./SuiviDetail";
import { ApprenantMultipleSuiviPage, SuiviDocuments } from "..";

export interface SuiviItemPageProps {}

export const SuiviItemPage: React.FunctionComponent<SuiviItemPageProps> = props => {
  const { t } = useTl();
  const { id, tab, state = "edit", mode } = useParams<{
    id: string;
    tab: string;
    state: string;
    mode: "hope" | "walter";
  }>();
  const history = useHistory();

  return (
    <PageBase breadCrumbs={[{ text: t(ETLCodes.Suivis), route: ERoutes.suivi }]}>
      <ProtectedTabs
        id="TabsSuivi"
        onChange={newTabId => history.push(`${ERoutes.suivi}/${id}/${newTabId}/${state}/${mode}`)}
        selectedTabId={tab}
        renderActiveTabPanelOnly
      >
        <BackButton backRoute={ERoutes.suivi}></BackButton>
        <Tab id="detail" title={t(ETLCodes.Detail)} panel={<SuiviDetail idsuivi={id} editMode={state === "edit"} />} />
        <Tab id="documents" title={t(ETLCodes.Documents)} panel={<SuiviDocuments />} disabled={+id <= 0} />
        <Tab
          id="multiple"
          title={t(ETLCodes.CreationMultiple)}
          panel={<ApprenantMultipleSuiviPage mode={mode} />}
          hidden={tab !== "multiple"}
        />
      </ProtectedTabs>
    </PageBase>
  );
};
