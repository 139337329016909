/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EContentHidingMode } from './EContentHidingMode';
import {
    EContentHidingModeFromJSON,
    EContentHidingModeFromJSONTyped,
    EContentHidingModeToJSON,
} from './EContentHidingMode';
import type { SuiviApprenantActionEditDto } from './SuiviApprenantActionEditDto';
import {
    SuiviApprenantActionEditDtoFromJSON,
    SuiviApprenantActionEditDtoFromJSONTyped,
    SuiviApprenantActionEditDtoToJSON,
} from './SuiviApprenantActionEditDto';

/**
 * 
 * @export
 * @interface SuiviWalterMultipleEditDto
 */
export interface SuiviWalterMultipleEditDto {
    /**
     * 
     * @type {number}
     * @memberof SuiviWalterMultipleEditDto
     */
    idsuivi?: number;
    /**
     * 
     * @type {number}
     * @memberof SuiviWalterMultipleEditDto
     */
    idclasse?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SuiviWalterMultipleEditDto
     */
    idapprenant?: number;
    /**
     * 
     * @type {Date}
     * @memberof SuiviWalterMultipleEditDto
     */
    dateSuivi?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof SuiviWalterMultipleEditDto
     */
    contenu?: string | null;
    /**
     * 
     * @type {EContentHidingMode}
     * @memberof SuiviWalterMultipleEditDto
     */
    contentHidingMode?: EContentHidingMode;
    /**
     * 
     * @type {number}
     * @memberof SuiviWalterMultipleEditDto
     */
    creationUser?: number;
    /**
     * 
     * @type {Date}
     * @memberof SuiviWalterMultipleEditDto
     */
    creationDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof SuiviWalterMultipleEditDto
     */
    modificationUser?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof SuiviWalterMultipleEditDto
     */
    modificationDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof SuiviWalterMultipleEditDto
     */
    idlieuRencontre?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SuiviWalterMultipleEditDto
     */
    idcentre?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SuiviWalterMultipleEditDto
     */
    idrealisateurSuivi?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SuiviWalterMultipleEditDto
     */
    idformateur?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SuiviWalterMultipleEditDto
     */
    idmode?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SuiviWalterMultipleEditDto
     */
    idmetier?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SuiviWalterMultipleEditDto
     */
    idsousTypeSuivi?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SuiviWalterMultipleEditDto
     */
    idtypeSuivi?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SuiviWalterMultipleEditDto
     */
    autreSousType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SuiviWalterMultipleEditDto
     */
    encodeur?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SuiviWalterMultipleEditDto
     */
    action?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SuiviWalterMultipleEditDto
     */
    fromSmile?: boolean;
    /**
     * 
     * @type {SuiviApprenantActionEditDto}
     * @memberof SuiviWalterMultipleEditDto
     */
    suiviAction?: SuiviApprenantActionEditDto;
    /**
     * 
     * @type {Array<number>}
     * @memberof SuiviWalterMultipleEditDto
     */
    idsApprenant?: Array<number> | null;
}

/**
 * Check if a given object implements the SuiviWalterMultipleEditDto interface.
 */
export function instanceOfSuiviWalterMultipleEditDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SuiviWalterMultipleEditDtoFromJSON(json: any): SuiviWalterMultipleEditDto {
    return SuiviWalterMultipleEditDtoFromJSONTyped(json, false);
}

export function SuiviWalterMultipleEditDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SuiviWalterMultipleEditDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idsuivi': !exists(json, 'idsuivi') ? undefined : json['idsuivi'],
        'idclasse': !exists(json, 'idclasse') ? undefined : json['idclasse'],
        'idapprenant': !exists(json, 'idapprenant') ? undefined : json['idapprenant'],
        'dateSuivi': !exists(json, 'dateSuivi') ? undefined : (json['dateSuivi'] === null ? null : new Date(json['dateSuivi'])),
        'contenu': !exists(json, 'contenu') ? undefined : json['contenu'],
        'contentHidingMode': !exists(json, 'contentHidingMode') ? undefined : EContentHidingModeFromJSON(json['contentHidingMode']),
        'creationUser': !exists(json, 'creationUser') ? undefined : json['creationUser'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (json['creationDate'] === null ? null : new Date(json['creationDate'])),
        'modificationUser': !exists(json, 'modificationUser') ? undefined : json['modificationUser'],
        'modificationDate': !exists(json, 'modificationDate') ? undefined : (json['modificationDate'] === null ? null : new Date(json['modificationDate'])),
        'idlieuRencontre': !exists(json, 'idlieuRencontre') ? undefined : json['idlieuRencontre'],
        'idcentre': !exists(json, 'idcentre') ? undefined : json['idcentre'],
        'idrealisateurSuivi': !exists(json, 'idrealisateurSuivi') ? undefined : json['idrealisateurSuivi'],
        'idformateur': !exists(json, 'idformateur') ? undefined : json['idformateur'],
        'idmode': !exists(json, 'idmode') ? undefined : json['idmode'],
        'idmetier': !exists(json, 'idmetier') ? undefined : json['idmetier'],
        'idsousTypeSuivi': !exists(json, 'idsousTypeSuivi') ? undefined : json['idsousTypeSuivi'],
        'idtypeSuivi': !exists(json, 'idtypeSuivi') ? undefined : json['idtypeSuivi'],
        'autreSousType': !exists(json, 'autreSousType') ? undefined : json['autreSousType'],
        'encodeur': !exists(json, 'encodeur') ? undefined : json['encodeur'],
        'action': !exists(json, 'action') ? undefined : json['action'],
        'fromSmile': !exists(json, 'fromSmile') ? undefined : json['fromSmile'],
        'suiviAction': !exists(json, 'suiviAction') ? undefined : SuiviApprenantActionEditDtoFromJSON(json['suiviAction']),
        'idsApprenant': !exists(json, 'idsApprenant') ? undefined : json['idsApprenant'],
    };
}

export function SuiviWalterMultipleEditDtoToJSON(value?: SuiviWalterMultipleEditDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idsuivi': value.idsuivi,
        'idclasse': value.idclasse,
        'idapprenant': value.idapprenant,
        'dateSuivi': value.dateSuivi === undefined ? undefined : (value.dateSuivi === null ? null : value.dateSuivi.toISOString()),
        'contenu': value.contenu,
        'contentHidingMode': EContentHidingModeToJSON(value.contentHidingMode),
        'creationUser': value.creationUser,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate === null ? null : value.creationDate.toISOString()),
        'modificationUser': value.modificationUser,
        'modificationDate': value.modificationDate === undefined ? undefined : (value.modificationDate === null ? null : value.modificationDate.toISOString()),
        'idlieuRencontre': value.idlieuRencontre,
        'idcentre': value.idcentre,
        'idrealisateurSuivi': value.idrealisateurSuivi,
        'idformateur': value.idformateur,
        'idmode': value.idmode,
        'idmetier': value.idmetier,
        'idsousTypeSuivi': value.idsousTypeSuivi,
        'idtypeSuivi': value.idtypeSuivi,
        'autreSousType': value.autreSousType,
        'encodeur': value.encodeur,
        'action': value.action,
        'fromSmile': value.fromSmile,
        'suiviAction': SuiviApprenantActionEditDtoToJSON(value.suiviAction),
        'idsApprenant': value.idsApprenant,
    };
}

