/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ETypeApprenantFichier {
    CARTE_IDENTITE = 'CARTE_IDENTITE',
    FICHE_FSE = 'FICHE_FSE',
    DOSSIER_SCOLAIRE = 'DOSSIER_SCOLAIRE',
    DOSSIER_SCOLAIRE_ACCUSE_FIC = 'DOSSIER_SCOLAIRE_ACCUSE_FIC',
    ROI_CARNET_PEDA = 'ROI_CARNET_PEDA',
    CHARTE_ENGAGEMENT = 'CHARTE_ENGAGEMENT',
    AVIS_RESULTATS = 'AVIS_RESULTATS',
    AVIS_RESULTATS_1ERE_SESSION = 'AVIS_RESULTATS_1ERE_SESSION',
    AVIS_RESULTATS_2EME_SESSION = 'AVIS_RESULTATS_2EME_SESSION',
    AVIS_RESULTATS_EXTERNE = 'AVIS_RESULTATS_EXTERNE',
    PF_ENTREPRISE = 'PF_ENTREPRISE',
    CERTIFICAT_MEDICAL = 'CERTIFICAT_MEDICAL',
    JUSTIFICATIF = 'JUSTIFICATIF',
    ATTESTATION_REUSSITE = 'ATTESTATION_REUSSITE',
    PPD_AUTORISATION_PARENTALE = 'PPD_AUTORISATION_PARENTALE',
    PPD_CONSENTEMENT_COLLECTE = 'PPD_CONSENTEMENT_COLLECTE',
    AUTRES = 'AUTRES'
}


export function ETypeApprenantFichierFromJSON(json: any): ETypeApprenantFichier {
    return ETypeApprenantFichierFromJSONTyped(json, false);
}

export function ETypeApprenantFichierFromJSONTyped(json: any, ignoreDiscriminator: boolean): ETypeApprenantFichier {
    return json as ETypeApprenantFichier;
}

export function ETypeApprenantFichierToJSON(value?: ETypeApprenantFichier | null): any {
    return value as any;
}

