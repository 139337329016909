import { Classes, Dialog } from "@blueprintjs/core";
import { format, startOfDay } from "date-fns";
import { FGListen } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";
import * as Yup from "yup";

import { ParcoursFormationApi, ParcoursFormationEditDto, ParcoursFormationEditDtoFromJSON } from "../../api";
import { useApiService, useTl } from "../../hooks";
import { useReferential } from "../../hooks/useReferential";
import { ETLCodes } from "../../locales";
import { FGWalterDateMaskInput, FGWalterSelectInput } from "../formGenerator";
import { SmallFormGenerator } from "../SmallFormGenerator";

const StyledDialog = styled(Dialog)`
  width: 600px;
  height: auto;
  background-color: white;

  & .${Classes.RADIO} {
    align-self: center;
    min-width: 125px;
  }
`;

export interface IAddParcoursFormationDialogProps {
  idApprenant: number;
  idCategorieStatut?: number;
  idStatut?: number;
  dateEntree?: Date;
  minDateEntree?: Date;
  isDialogOpen: boolean;
  onClose: (refresh: boolean) => void;
  title: string;
  iddureeInoccupation?: number;
}

export const AddParcoursFormationDialog: React.FunctionComponent<IAddParcoursFormationDialogProps> = ({
  idApprenant,
  isDialogOpen,
  onClose = (refresh: boolean) => {},
  idCategorieStatut,
  idStatut,
  dateEntree,
  title,
  minDateEntree,
  iddureeInoccupation
}) => {
  const { t } = useTl();
  const api = useApiService(ParcoursFormationApi);
  const [idCatStatutSocial, setIdCatStatutSocial] = React.useState(idCategorieStatut);
  const [saving, setSaving] = React.useState(false);

  const [categoriesStatutSocial, cssLoading, , rawCatStatutSocial] = useReferential(
    a => a.referentialGetCategoriesStatutSocial({ currentIdcategorieStatutSocial: idCatStatutSocial }),
    true,
    [idCatStatutSocial]
  );
  const [idstatutSocial, setIdstatutSocial] = React.useState(0);
  const [statutsSocial, ssLoading] = useReferential(
    a =>
      a.referentialGetSousStatutsByCategorie({ idCategorie: idCatStatutSocial, currentIdstatutSocial: idstatutSocial }),
    true,
    [idCatStatutSocial, idstatutSocial]
  );

  const saveItem = React.useCallback(
    async (d: ParcoursFormationEditDto) => {
      setSaving(true);
      await api.parcoursFormationSaveParcoursFormation({ ParcoursFormationEditDto: d });
      setSaving(false);

      onClose(true);
    },
    [api, onClose]
  );

  const data = React.useMemo(() => {
    return ParcoursFormationEditDtoFromJSON({
      idApprenant,
      idCategorieStatut,
      idStatut,
      dateEntree: dateEntree ?? new Date(),
      iddureeInoccupation
    });
  }, [dateEntree, idApprenant, idCategorieStatut, idStatut, iddureeInoccupation]);

  const FormSchema = React.useMemo(() => {
    let schema = {
      idCategorieStatut: Yup.number()
        .nullable()
        .required(t(ETLCodes.Required)),
      idStatut: Yup.number()
        .nullable()
        .required(t(ETLCodes.Required)),
      dateEntree: Yup.date()
        .nullable()
        .required(t(ETLCodes.Required)),
      iddureeInoccupation: Yup.number()
        .nullable()
        .when(["idCategorieStatut"], {
          is: idCategorieStatut =>
            !!rawCatStatutSocial &&
            +(rawCatStatutSocial.find(c => c.keyValue === "DEM")?.idValue ?? 0) === +idCategorieStatut,
          then: Yup.number()
            .nullable()
            .required(t(ETLCodes.Required))
        })
    };
    if (!!minDateEntree)
      schema = {
        ...schema,
        dateEntree: Yup.date()
          .nullable()
          .required(t(ETLCodes.Required))
          .min(startOfDay(minDateEntree), t(ETLCodes.DateCannotBeBefore, { date: format(minDateEntree, "dd-MM-yyyy") }))
      };

    return Yup.object().shape(schema);
  }, [minDateEntree, rawCatStatutSocial, t]);
  const [dureeInoccupations, diLoading] = useReferential(a => a.referentialGetDureeInoccupation(), true, []);

  const showDureeInoccupation = React.useMemo(
    () =>
      !!rawCatStatutSocial &&
      +(rawCatStatutSocial.find(c => c.keyValue === "DEM")?.idValue ?? 0) === +idCatStatutSocial,
    [idCatStatutSocial, rawCatStatutSocial]
  );

  return (
    <StyledDialog title={title} isOpen={isDialogOpen} onClose={() => onClose(false)}>
      <div className={Classes.DIALOG_BODY}>
        <SmallFormGenerator
          initialValues={data}
          onSubmit={saveItem}
          editMode={true}
          validationSchema={FormSchema}
          onCancel={() => onClose(false)}
          showDeleteButton={false}
          saving={saving}
          forceEnableSave
          watchChanges={{
            idStatut: setIdstatutSocial
          }}
          enableDirtyCheck={false}
        >
          <FGListen
            field={"idCategorieStatut"}
            onChanged={(value: any) => {
              setIdstatutSocial(null);
              setIdCatStatutSocial(value);
            }}
          />
          <FGWalterSelectInput
            name="idCategorieStatut"
            label={t(ETLCodes.CategorieStatut)}
            items={categoriesStatutSocial}
            loading={cssLoading}
          />
          <FGWalterSelectInput name="idStatut" label={t(ETLCodes.Statut)} items={statutsSocial} loading={ssLoading} />
          {showDureeInoccupation && (
            <FGWalterSelectInput
              name="iddureeInoccupation"
              label={t(ETLCodes.DureeInoccupation)}
              items={dureeInoccupations}
              loading={diLoading}
              requiredMark
            />
          )}
          <FGWalterDateMaskInput name="dateEntree" label={t(ETLCodes.DateEntreeParcours)} minDate={minDateEntree} />
        </SmallFormGenerator>
      </div>
    </StyledDialog>
  );
};
